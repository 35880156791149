import {Injectable} from '@angular/core';
import {InsightsIdentityService} from './insights-identity.service';
import {ApplicationStateService} from './application-state.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {LoginData} from '../entities/LoginData';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    constructor(protected insightsIdentityService: InsightsIdentityService,
                protected applicationState: ApplicationStateService,
                protected router: Router,
                protected http: HttpClient) {
    }

    login(loginData: LoginData, componentCallback) {
        this.insightsIdentityService.login(loginData, componentCallback);
    }

    requestPasswordReset(email, callback) {
        return this.insightsIdentityService.requestPasswordReset(email, callback);
    }

    logout() {
        this.insightsIdentityService.logout();
    }

    updatePassword(email, verificationCode, password, callback) {
        this.insightsIdentityService.updatePassword(email, verificationCode, password, callback);
    }

    loggedIn() {
        return this.insightsIdentityService.loggedIn();
    }

    setLoggedIn(status: string): void {
        this.insightsIdentityService.setLoggedIn(status);
    }

}
