import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor} from '@angular/common/http';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NotificationService} from './notification.service';
import {InsightsIdentityService} from './insights-identity.service';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private refreshTokenInProgress: boolean = false;
    private headerName = 'X-XSRF-TOKEN';
    private _401intercepted = false;

    constructor(private notificationService: NotificationService,
                private tokenExtractor: HttpXsrfTokenExtractor,
                private insightsIdentityService: InsightsIdentityService,
                @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
                protected router: Router) {
        this.refreshTokenInProgress = false;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let clonedRequest = req.clone({withCredentials: true});
        const token = this.tokenExtractor.getToken() as string;
        if (token !== null && !req.headers.has(this.headerName)) {
            clonedRequest = req.clone({headers: req.headers.set(this.headerName, token), withCredentials: true});
        }
        return next.handle(clonedRequest).pipe(
            map((res: any) => {
                this._401intercepted = false;
                return res;
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    handleUnauthorized() {
        let url = null;
        if (window.location.href.includes('dashboard')) {
            url = 'dashboard' + window.location.href.split('dashboard')[1];
        }
        this.insightsIdentityService.handleUnauthorized(url);
    }

    showErrorNotification(error) {
        console.log('error information', error);

        if (error.error instanceof ErrorEvent) {
            // client-side error
            this.notificationService.error(
                error.error.message,
                'Something went wrong: ' + error.error.error);
        } else {
            // server-side error
            this.notificationService.error(
                error.message,
                'Something went wrong: ' + error.status);
        }
    }

    handleError(err) {
        if (err.status === 401) {
            if (!this._401intercepted) {
                this._401intercepted = true;
                if (err.error == null || (err.error && err.error.message === 'Access denied')) {
                    this.handleUnauthorized();
                    throwError(null);
                }
                if (err.error && err.error.message === 'Bad credentials') {
                    return throwError(err);
                }
            } else {
                // ignore all requests after first 401
                return EMPTY;
            }
        } else if (err.status === 400) { // validation error server side, let the component handle it
            return throwError(err);
        } else if (err.status === 403) {
            return throwError(err);
        } else if (err.status === 409) {
            return throwError(err);
        } else if (err.status === 503) { // Async process is running but not completed (timeout warning to be handled in the frontend)
            return throwError(err);
        } else if (!err.error?.message && err.error?.message !== 'Bad credentials') {
            this.showErrorNotification(err);
        }

        return throwError(err);
    }
}
