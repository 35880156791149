import {NgModule} from '@angular/core';
import {TranslatePipe} from './translate.pipe';
import {StyleKpiPipe} from './style-kpi.pipe';
import {AlphabeticalOrderPipe} from './alphabetical-order.pipe';
import {DayDatePipe} from './day-date.pipe';
import {GrpsPipe} from './grps.pipe';
import {IdFormatterPipe} from './idFormatter';
import {WeekNamePipe} from './week-name-pipe.pipe';
import {DateFormatPipe} from './date-format.pipe';
import {DatetimeFormatPipe} from './datetime-format.pipe';
import {MspStatusPipe} from './msp-status.pipe';
import {MediaGroupByPipe} from './media-group-by.pipe';
import {ModelRunStatusPipe} from '@/app/pipes/model-config-status.pipe';
import {ModelConfigDistributionPipe} from '@/app/pipes/model-config-distribution.pipe';
import {ModelConfigShapePipe} from '@/app/pipes/model-config-shape.pipe';
import {DynamicFilteringPipe} from '@/app/pipes/dynamic-filtering.pipe';
import {ModelConfigContextualEffectPipe} from '@/app/pipes/model-config-contextual-effect.pipe';
import {MspCampaignStatusPipe} from '@/app/pipes/msp-campaign-status.pipe';
import {NotApplicablePipe} from '@/app/pipes/not-applicable.pipe';
import {SearchPipe} from '@/app/pipes/search.pipe';
import {OpNumberPipe} from '@/app/pipes/op-number.pipe';
import {NotificationTypePipe} from '@/app/pipes/notification-type.pipe';
import {DateAgoPipe} from '@/app/pipes/date-ago.pipe';
import {SelectableItemsTransformerPipe} from '@/app/pipes/selectable-items-transformer.pipe';
import {MspBusinessDynamicsTypePipe} from '@/app/pipes/msp-business-dynamics-type.pipe';
import {PercentageDifferencePipe} from '@/app/pipes/percentage-difference.pipe';

@NgModule({
    declarations: [
        TranslatePipe,
        StyleKpiPipe,
        AlphabeticalOrderPipe,
        DayDatePipe,
        GrpsPipe,
        IdFormatterPipe,
        WeekNamePipe,
        DateFormatPipe,
        DatetimeFormatPipe,
        MspStatusPipe,
        MediaGroupByPipe,
        ModelRunStatusPipe,
        ModelConfigDistributionPipe,
        ModelConfigShapePipe,
        DynamicFilteringPipe,
        SelectableItemsTransformerPipe,
        ModelConfigContextualEffectPipe,
        MspCampaignStatusPipe,
        NotApplicablePipe,
        SearchPipe,
        OpNumberPipe,
        NotificationTypePipe,
        DateAgoPipe,
        MspBusinessDynamicsTypePipe,
        PercentageDifferencePipe,
    ],
    imports: [],
    exports: [
        AlphabeticalOrderPipe,
        TranslatePipe,
        StyleKpiPipe,
        DayDatePipe,
        GrpsPipe,
        IdFormatterPipe,
        WeekNamePipe,
        DateFormatPipe,
        DatetimeFormatPipe,
        MspStatusPipe,
        MediaGroupByPipe,
        ModelRunStatusPipe,
        ModelConfigDistributionPipe,
        ModelConfigShapePipe,
        DynamicFilteringPipe,
        SelectableItemsTransformerPipe,
        ModelConfigContextualEffectPipe,
        MspCampaignStatusPipe,
        NotApplicablePipe,
        SearchPipe,
        OpNumberPipe,
        NotificationTypePipe,
        DateAgoPipe,
        MspBusinessDynamicsTypePipe,
        PercentageDifferencePipe,
    ],
})
export class PipesModule {
}
