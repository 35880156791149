import {Injectable} from '@angular/core';
import {User} from '../entities/User';
import {environment} from '@/environments/environment';
import mixpanel from 'mixpanel-browser/src/loader-module';

@Injectable({
    providedIn: 'root'
})
export class MixpanelService {

    constructor() {
    }

    public init(): void {
        if (environment.enableTracking) {
            mixpanel.init(environment.mixpanelToken);
        }
    }

    public initUser(user: User): void {
        if (environment.enableTracking) {
            const properties = this.getProperties(user);
            mixpanel.identify(user._id);
            mixpanel.people.set(properties);
        }
    }

    public track(action: string, properties: any = {}): void {
        if (environment.enableTracking) {
            mixpanel.track(action, properties);
        }
    }

    private getProperties(user: User): any {
        const properties = {
            '$distinct_id': user._id,
            '$first_name': user.firstName,
            '$last_name': user.lastName,
            '$email': user.username
        };

        for (let i = 0; i < user.userGroups.length; i++) {
            const userGroup = user.userGroups[i];
            properties['User group ' + userGroup.userGroup?.id] =
                userGroup.userGroup?.client + ' (' + userGroup.userGroup?.name + ', ' + userGroup.accessLevel + ')';
        }
        return properties;
    }

}
