import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router, RouteReuseStrategy} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HIGHCHARTS_MODULES} from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exportingdata from 'highcharts/modules/export-data.src';
import * as exporting from 'highcharts/modules/exporting.src';
import {environment} from '@/environments/environment';
import {AppComponent} from './app.component';
import {DatepickerHeaderComponent} from './components/datepicker/datepicker.component';
import {LoadingComponent} from './components/loading/loading.component';
import {ProgressbarRoundComponent} from './components/progressbar-round/progressbar-round.component';
import {ProgressbarComponent} from './components/progressbar/progressbar.component';
import {DashboardContainerComponent} from './pages/dashboard-container/dashboard-container.component';
import {SisenseRedirectComponent} from './pages/sisense-redirect/sisense-redirect.component';
import {AppRoutingModule} from './routing/app-routing.module';
import {CustomRouteReuseStrategy} from './routing/custom-route-reuse-strategy.provider';
import {AuthInterceptor, DEFAULT_TIMEOUT} from './services/auth-interceptor.service';
import {ChartdataTransformationService} from './services/chartdata-transformation.service';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import {MatIconModule} from '@angular/material/icon';
import {MatFilterService} from './components/mat-table-filters/mat-filter.service';
import {CurrencyPipe, DecimalPipe, LocationStrategy, PercentPipe} from '@angular/common';
import * as Sentry from '@sentry/angular-ivy';
import {CustomDateAdapter} from './utilities/customDateAdapter';
import {CustomHttpParameterEncoder} from './services/custom-http-parameter-encoder.service';
import {ApplicationInitService} from './services/application-init.service';
import {SidebarNavigationComponent} from './components/sidebar-navigation/sidebar-navigation.component';
import {SidebarExtrasComponent} from './components/sidebar-extras/sidebar-extras.component';
import {TopMenuComponent} from './components/top-menu/top-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {AuthComponent} from './components/auth/auth.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {ToastrModule} from 'ngx-toastr';
import {OppieMainComponent} from './components/oppie-main/oppie-main.component';
import {PipesModule} from './pipes/pipes.module';
import {ComponentsModule} from './components/components.module';
import {DirectivesModule} from './directives/directives.module';
import {RxStompService} from '@/app/rx-stomp.service';
import {rxStompServiceFactory} from '@/app/rx-stomp-service-factory';
import {PlotlyModule} from 'angular-plotly.js';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import {PathPreserveQueryLocationStrategy} from '@/app/routing/path-preserve-query-location-strategy.provider';
import {EffectsModule} from '@ngrx/effects';
import {metaReducers, reducers} from '@/app/reducers';
import {StoreModule} from '@ngrx/store';
import {ScheduledNotificationEffects} from '@/app/reducers/notification/scheduled-notification/scheduled-notification.effects';
import {UserNotificationEffects} from '@/app/reducers/notification/user-notification/user-notification.effects';
import {OppieLoadingModule} from '@/app/components/oppie-loading/oppie-loading.module';

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [
        AppComponent,
        DatepickerHeaderComponent,
        ProgressbarComponent,
        DashboardContainerComponent,
        ProgressbarRoundComponent,
        SisenseRedirectComponent,
        LoadingComponent,
        SidebarNavigationComponent,
        SidebarExtrasComponent,
        TopMenuComponent,
        MenuItemComponent,
        AuthComponent,
        BreadcrumbComponent,
        OppieMainComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(), // ToastrModule added
        NgbModule,
        HttpClientModule,
        FormsModule,
        MatIconModule,
        PipesModule,
        MatRadioModule,
        ComponentsModule,
        DirectivesModule,
        PlotlyModule,
        MatTabsModule,
        OppieLoadingModule,

        // reducer
        StoreModule.forRoot(reducers, {
            metaReducers,
        }),
        EffectsModule.forRoot([
            ScheduledNotificationEffects,
            UserNotificationEffects,
        ]),
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({showDialog: false}),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        MatFilterService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: CustomHttpParameterEncoder, multi: true},
        {provide: DEFAULT_TIMEOUT, useValue: 30000},
        {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
        {provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy},
        ChartdataTransformationService,
        {provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, exportingdata]},
        {provide: 'googleTagManagerId', useValue: environment.gtm_id},
        {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS},
        CurrencyPipe,
        PercentPipe,
        DecimalPipe,
        {
            provide: APP_INITIALIZER,
            useFactory: (ais: ApplicationInitService) => () => ais.init(),
            deps: [ApplicationInitService],
            multi: true,
        },
        {
            provide: RxStompService,
            useFactory: rxStompServiceFactory,
        },
    ],
    exports: [],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule {
    constructor(trace: Sentry.TraceService) {
    }
}
