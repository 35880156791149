<div class="container-fluid">
    <div>
        <app-sidebar-navigation></app-sidebar-navigation>
        <main role="main">
            <app-top-menu></app-top-menu>
            <router-outlet></router-outlet>
        </main>
        <app-oppie-main></app-oppie-main>
    </div>
</div>
