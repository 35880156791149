import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApplicationStateService} from '../../services/application-state.service';
import {BreadcrumbService} from '../../services/breadcrumb.service';
import {BreadCrumb, RouteInfo} from '../../entities/BreadCrumb';
import {Title} from '@angular/platform-browser';
import {Location} from '@angular/common';
import {combineLatest, Subscription} from 'rxjs';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
    breadcrumbs: BreadCrumb[] = [];
    routes;
    subscription = new Subscription();

    constructor(
        private applicationState: ApplicationStateService,
        private location: Location,
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
        private titleService: Title) {
    }

    ngOnInit(): void {
        this.subscription.add(
            combineLatest([this.breadcrumbService.getRoutesObservable(), this.breadcrumbService.getCurrentRouteObservable()]).subscribe(results => {
                const routes = results[0];
                const routeId = results[1];
                this.routes = routes ?? [];

                let foundRoute = null;

                // find by specific routeId
                if (routeId) {
                    foundRoute = this.routes.find(item => item.id === routeId);

                    // clear routerId
                    this.breadcrumbService.setCurrentRouteId(null);
                } else {
                    // find by specific route path
                    foundRoute = this.routes.find(item =>
                        this.isCurrentRoute('/' + this.route.snapshot.routeConfig.path + '/' + item.path));
                }

                if (!foundRoute || !foundRoute.breadcrumb) {
                    return;
                }

                this.breadcrumbs = [];
                const breadcrumb = foundRoute.breadcrumb;
                this.breadcrumbs.push(new BreadCrumb(
                    this.route.snapshot.firstChild.routeConfig.path,
                    breadcrumb.title,
                ));
                if (breadcrumb.childOf != null) {
                    this.parseParent(breadcrumb.childOf);
                }
                this.breadcrumbs.reverse();
                this.breadcrumbService.setBreadcrumbs(this.breadcrumbs);

            })
        );
    }

    ngOnDestroy() {
        this.titleService.setTitle('Objective Platform');
        this.subscription.unsubscribe();
    }

    private parseParent(childOf) {
        for (const route of this.routes) {
            if (route.breadcrumb != null && route.id === childOf) {
                this.breadcrumbs.push(new BreadCrumb(this.replaceParams(route), route.breadcrumb.title));
                if (route.breadcrumb.childOf != null) {
                    this.parseParent(route.breadcrumb.childOf);
                }
            }
        }
    }

    /**
     * Replace params (:someId) by the real value
     * @param route
     * @private
     */
    private replaceParams(route: RouteInfo): string {
        if (route.path.includes(':')) {
            const fullPath = '/' + this.route.snapshot.routeConfig.path + '/' + route.path;
            const locationArray = this.location.path().split('/');

            let finalPath = '';
            fullPath.split('/').forEach((value, index) => {
                // if includes ':' than replace param
                if (value.includes(':')) {
                    value = locationArray[index];
                }
                finalPath += (value.length > 0 ? '/' : '') + value;
            });
            return finalPath;
        }
        return route.path;
    }

    private isCurrentRoute(url: string): boolean {
        if (url.endsWith('/')) {
            url = url.slice(0, url.length - 1);
        }
        let sameUrl = true;
        const urlArray = url.split('/');
        const routerUrlArray = this.location.path().split('?')[0].split('/');
        if (routerUrlArray.length === urlArray.length) {
            urlArray.forEach((value, index) => {
                const routerPiece = routerUrlArray[index];
                if (!(value.includes(':') || value === routerPiece)) {
                    sameUrl = false;
                }
            });
        } else {
            sameUrl = false;
        }
        return sameUrl;
    }
}
