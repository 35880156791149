import {Component, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ApplicationStateService} from './services/application-state.service';
import {first} from 'rxjs/operators';
import {AuthenticationService} from './services/authentication.service';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {InsightsIdentityService} from './services/insights-identity.service';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {environment} from '../environments/environment';
import {MixpanelService} from './services/mixpanel.service';
import {Title} from '@angular/platform-browser';
import {BreadcrumbService} from './services/breadcrumb.service';
import {BreadCrumb} from '@/app/entities/BreadCrumb';
import {Observable} from 'rxjs';
import {UserNotificationState} from '@/app/reducers/notification/notification.interface';
import {Store} from '@ngrx/store';
import * as froomRoot from './reducers';
import {loadUserNotifications} from '@/app/reducers/notification/user-notification/user-notification.action';
import {NotificationSseService} from '@/app/services/notification-sse.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
    title = 'New Frontend';
    @ViewChild('content', {read: TemplateRef}) content: TemplateRef<any>;
    modalOption: NgbModalOptions = {};

    userNotificationState: Observable<UserNotificationState>;

    constructor(private router: Router, private gtmService: GoogleTagManagerService,
                private insightsIdentityService: InsightsIdentityService, private modalService: NgbModal,
                private applicationStateService: ApplicationStateService,
                private authenticationService: AuthenticationService,
                private mixpanelService: MixpanelService,
                private breadcrumbService: BreadcrumbService,
                private titleService: Title,
                private notificationSseService: NotificationSseService,
                private store: Store<froomRoot.State>) {

        this.userNotificationState = store.select('userNotification');

        if (this.authenticationService.loggedIn()) {
            applicationStateService.startRenewSession();
            this.store.dispatch(loadUserNotifications());
            this.notificationSseService.subscribeUserNotifications();
        }
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.authenticationService.loggedIn()) {
                    this.applicationStateService.getState('clientConfiguration').pipe(first()).subscribe(clientConfiguration => {
                        if (event.urlAfterRedirects === '/dashboard') {
                            let redirectionURL = clientConfiguration.homepage.trim() !== ''
                                ? clientConfiguration.homepage : '/dashboard/channel-insights/media-explorer';
                            this.applicationStateService.getState('permissions').subscribe((userGroupAdminInfo) => {
                                if (userGroupAdminInfo.userGroup.userGroupDefaults.homepage != null &&
                                    userGroupAdminInfo.userGroup.userGroupDefaults.homepage !== '') {
                                    redirectionURL = userGroupAdminInfo.userGroup.userGroupDefaults.homepage.trim() !== ''
                                        ? userGroupAdminInfo.userGroup.userGroupDefaults.homepage : '/dashboard/channel-insights/media-explorer';
                                }
                                this.router.navigateByUrl(redirectionURL);
                                return;
                            });
                            return;
                        }
                        const user = this.applicationStateService.getUser();
                        this.gtmService.addGtmToDom();
                        this.breadcrumbService.getBreadcrumbsObservable().pipe(first()).subscribe((breadcrumbs: BreadCrumb[]) => {
                            let pageTitle = null;
                            let pageType = 'Other';
                            if (breadcrumbs != null) {
                                pageTitle = breadcrumbs[0].title;
                                this.titleService.setTitle('O|P - ' + pageTitle);
                                if (breadcrumbs[0].path && breadcrumbs[0].path.includes('universal-insights')) {
                                    pageType = 'Fire';
                                }
                            }
                            this.mixpanelService.track('Page view', {
                                client: clientConfiguration.client,
                                pageURL: event.urlAfterRedirects,
                                pageTitle: pageTitle,
                                pageType: pageType,
                            });
                        });
                        if (environment.enableTracking) {
                            this.gtmService.getDataLayer().push({
                                event: 'pageview',
                                path: event.urlAfterRedirects,
                                client: clientConfiguration.client,
                                userId: user._id,
                                internalUser: user.username.indexOf('objective') >= 0,
                                customerCompany: clientConfiguration.customerCompany,
                            });
                        }
                    });
                } else {
                    this.gtmService.addGtmToDom();
                    if (environment.enableTracking) {
                        this.gtmService.getDataLayer().push({
                            event: 'pageview',
                            path: event.urlAfterRedirects,
                        });
                    }
                }
            }
        });
        this.modalOption.backdrop = 'static';
        this.modalOption.keyboard = false;
        this.insightsIdentityService.appComponent$.subscribe(open => {
            this.openModal(open);
        });
    }

    ngOnDestroy(): void {
        this.notificationSseService.unsubscribeUserNotifications();
    }

    private openModal(open) {
        if (open) {
            this.modalService.open(this.content, this.modalOption);
        } else {
            this.modalService.dismissAll();
        }
    }

}
