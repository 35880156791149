<div *ngIf="oppieEnabled && isCampaignEditorRoute() && currentEvent" class="oppie-container">
    <div *ngIf="!popupClosed" class="oppie-dialog">
        <mat-icon (click)="popupClosed = !popupClosed" *ngIf="currentEvent.dismissText || currentEvent.actionText"
                  class="close-button float-right">close
        </mat-icon>
        <p [innerHTML]="currentEvent.messageToUser" [ngClass]="{'oppie-message' : (!currentEvent.dismissText && !currentEvent.actionText)}"></p>
        <div [ngClass]="{'flex-justify-between': (currentEvent.dismissText && currentEvent.actionText)}" class="mt-2">
            <button (click)="dismiss()" *ngIf="currentEvent.dismissText"
                    class="btn btn-link oppie-dialog-action oppie-decline-action">{{ currentEvent.dismissText }}
            </button>
            <button (click)="sendBackEvent(currentEvent)" *ngIf="currentEvent.actionText"
                    class="btn btn-link oppie-dialog-action oppie-confirm-action">{{ currentEvent.actionText }}
            </button>
        </div>
    </div>
    <img (click)="popupClosed = !popupClosed" alt="oppie-avatar"
         class="oppie-avatar" height="90" src="/assets/oppie-avatar.png" width="90"/>
</div>
