<router-outlet></router-outlet>
<ng-template #content let-modal>
    <div class="modal-body">
        <div class="row p-4">
            Your session has expired.
        </div>
        <div class="row p-4">
            You will be redirected to login screen in 2 seconds...
        </div>
    </div>
</ng-template>