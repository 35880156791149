import {Injectable} from '@angular/core';
import {ApplicationStateService} from './application-state.service';
import {TranslationService} from './translation.service';
import {MixpanelService} from './mixpanel.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationInitService {

    constructor(private applicationStateService: ApplicationStateService,
                private mixpanelService: MixpanelService) {
    }

    init(): Promise<any> {
        if (localStorage.getItem('isLoggedIn') === 'true') {
            this.mixpanelService.init();
            return new Promise((resolve) => {
                this.applicationStateService.getState('clientConfiguration', true).subscribe(clientConfig => {
                    if (clientConfig) { // clientConfig can be null if token expired and refreshing page
                        TranslationService.initTranslations(clientConfig.translations);
                    }
                    resolve(true);
                });
            });
        } else {
            return new Promise((resolve) => {
                resolve(true);
            });
        }
    }
}
