import {Component, OnDestroy, OnInit} from '@angular/core';
import {OppieEventsStreamService} from '../../services/oppie-events-stream.service';
import {EventOrigin, EventType, OppieEvent} from '../../entities/OppieEvent';
import {EventFactory} from '../../utilities/eventFactory';
import {Subscription} from 'rxjs';
import {ApplicationStateService} from '../../services/application-state.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-oppie-main',
    templateUrl: './oppie-main.component.html',
    styleUrls: ['./oppie-main.component.scss'],
})
export class OppieMainComponent implements OnInit, OnDestroy {
    public oppieEnabled = false;
    currentEvent: OppieEvent = null;
    previousEvent: OppieEvent = null;
    public popupClosed = false;
    private subscription: Subscription;
    private readonly currentContext = EventOrigin.OPPIE;

    constructor(private eventStreamService: OppieEventsStreamService, private applicationState: ApplicationStateService, private router: Router) {
        this.subscription = new Subscription();
    }

    ngOnInit(): void {

        this.subscription.add(
            this.applicationState.getState('permissions').subscribe(permissions => {
                this.oppieEnabled = permissions.userGroup.userGroupPermissions.oppieEnabled;
            }));
        this.subscription.add(
            this.eventStreamService.eventsStream.subscribe({
                next: (oppieEvent: OppieEvent) => {
                    if (oppieEvent.origin !== EventOrigin.OPPIE) {
                        this.handleEvent(oppieEvent);
                    }
                },
            }),
        );
    }

    dismiss() {
        this.popupClosed = true;
        if (this.currentEvent.eventType === EventType.CAMPAIGN_OPTIMIZATION_FAILED) {
            this.handleEvent(EventFactory.createEvent(EventType.CAMPAIGN_OPTIMIZATION));
        }

    }

    sendBackEvent(currentEvent: OppieEvent) {
        const response = EventFactory.createResponseEvent(currentEvent);
        this.eventStreamService.publishEvent(response);
    }

    /**
     * Oppie should only be visible in:
     * 1 . campaign editor (while adding a new campaign or editing an old one)
     * 2 . campaign-overview page.
     */
    public isCampaignEditorRoute(): boolean {
        return this.router.url.includes('edit-campaign') || this.router.url.includes('edit-plan');
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private handleEvent(newEvent: OppieEvent) {
        if (newEvent.eventType === EventType.OPPIE_DISABLED && this.oppieEnabled) {
            this.previousEvent = this.currentEvent;
            this.oppieEnabled = false;
        }
        if (newEvent.eventType === EventType.OPPIE_ENABLED) {
            this.currentEvent = this.previousEvent;
            this.oppieEnabled = true;
            return;
        }
        if (newEvent.eventType === EventType.CAMPAIGN_RESET_CLOSE) {
            this.popupClosed = true;
            return;
        }
        if (newEvent.origin !== this.currentContext) {
            this.currentEvent = newEvent;
        }
    }
}
