export class BreadCrumb {
    path: string;
    title: string;

    constructor(path: string, title: string) {
        this.path = path;
        this.title = title;
    }
}

export class RouteInfo {
    id: string;
    path: string;
    breadcrumb: BreadCrumb;

    constructor(id: string, path: string, breadcrumb: BreadCrumb) {
        this.id = id;
        this.path = path;
        this.breadcrumb = breadcrumb;
    }
}