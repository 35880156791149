import {Injectable, NgZone} from '@angular/core';
import {find as _find, forEach as _forEach, intersection as _intersection, map as _map, without as _without} from 'lodash-es';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@/environments/environment';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {User} from '../entities/User';
import {LocationService} from '@/app/services/location.service';
import {UserClientConfig} from '@/app/entities/UserClientConfig';
import {ClientConfiguration} from '@/app/entities/ClientConfiguration';

interface DataCollection {
    [key: string]: BehaviorSubject<any>;
}

interface DataCollectionEmpty {
    [key: string]: BehaviorSubject<any>;
}

type Data = DataCollection | DataCollectionEmpty;

@Injectable({
    providedIn: 'root',
})
export class ApplicationStateService {
    data: Data;
    state: {};
    gettingStateInProgress: boolean;
    client: string;
    sessionInterval;
    SESSION_RENEW_TIME = 300000; // 5 minutes
    private user: User;

    constructor(protected http: HttpClient, protected ngZone: NgZone, protected locationService: LocationService) {
        this.data = {} as Data;
        this.state = {};
        this.gettingStateInProgress = false;
    }

    getBasicDimensions(client) {
        return this.http.get(environment.api_url + 'basics/dimensions').toPromise();
    }

    async resetState() {
        // Loops over the this.data of type { [key: string]: BehaviorSubject<any> }
        // and sets each of the BehaviorSubjects to null, thereby resetting the application state
        // except for `clientConfiguration`
        await _forEach(<Data>this.data,
            (value: BehaviorSubject<any>, key: string | number) => {
                if (key !== 'clientConfiguration') {
                    this.data[key].next(null);
                }
            });
        return;
    }

    getClient() {
        return this.client;
    }

    getUser(): User {
        return this.user;
    }

    setInitialState(data: UserClientConfig, client?) {
        let currentPermissions;
        const clientFromURL = new URLSearchParams(window.location.search).get('client');
        const clientFromMemory = localStorage.getItem('selected_client');
        if (data?.user && data?.user?.userGroups) {
            this.user = data.user;
            if (client) {
                currentPermissions = this.permissionsForClient(data, client);
            } else if (clientFromURL) {
                currentPermissions = this.permissionsForClient(data, clientFromURL);
                if (currentPermissions) {
                    client = clientFromURL;
                }
            } else if (clientFromMemory) {
                currentPermissions = this.permissionsForClient(data, clientFromMemory);
                if (currentPermissions) {
                    client = clientFromMemory;
                }
            }
            if (!currentPermissions) {
                this.addObservable('permissions', data.user.userGroups[0]);
                currentPermissions = data.user.userGroups[0];
                client = data.user.userGroups[0].userGroup.client;
                localStorage.setItem('selected_client', client);
            }
        }
        this.client = client;
        this.addObservable('userClientConfig', data);
        this.addObservable('userGroups', data.user.userGroups);

        if (data.clientConfigurationList) {
            const keys = Object.keys(data.clientConfigurationList);
            for (const i of keys) {
                if (data.clientConfigurationList[i].client === client) {
                    if (this.data['clientConfiguration']) {
                        this.updateState('clientConfiguration', data.clientConfigurationList[i]);
                    } else {
                        this.addObservable('clientConfiguration', data.clientConfigurationList[i]);
                    }
                }
            }
        } else {
            this.addObservable('clientConfiguration', {
                translations: {
                    conversions: 'Conversions',
                    cost_per_conversion: 'Cost per Conversion',
                    costs: 'Costs',
                    return_on_ads_spend: 'Return on ad spend',
                    revenue: 'Net Sales',
                },
                shinyPages: {},
            });
        }

        // last day of previous week
        const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
        const beforeOneWeek2 = new Date(beforeOneWeek);
        const day = beforeOneWeek.getDay();
        const diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
        const lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));
        const lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));
        const monday13WeeksAgo = new Date(lastMonday.getTime() - 60 * 60 * 24 * 7 * 1000 * 13); // first day of week 13 weeks ago

        const month = lastSunday.getMonth() + 1 < 10 ? '0' + (lastSunday.getMonth() + 1) : lastSunday.getMonth() + 1;
        const prevMonth = monday13WeeksAgo.getMonth() + 1 < 10 ? '0' + (monday13WeeksAgo.getMonth() + 1) : monday13WeeksAgo.getMonth() + 1;
        const prevDay = monday13WeeksAgo.getDate() < 10 ? '0' + monday13WeeksAgo.getDate() : monday13WeeksAgo.getDate();
        const endDay = lastSunday.getDate() < 10 ? '0' + lastSunday.getDate() : lastSunday.getDate();

        this.addObservable('dataControlsDisabled', true);
        this.addObservable('budgetOptimizationPlan', {});
        this.addObservable('calendarPlan', {});
        this.addObservable('event', {});
        this.addObservable('maxPercentageChange', null);
        this.addObservable('seriesVisibility', []);
        this.addObservable('chartControls', {
            chartType: 'column',
            verticalMetric: currentPermissions.userGroup && currentPermissions.userGroup.userGroupPermissions.kpiList[0] ?
                currentPermissions.userGroup.userGroupPermissions.kpiList[0] : 'revenue',
        });
        this.getBasicDimensions(client).then(allDimensions => {
            const validatedDims = this.validatedDimensionOptions(
                currentPermissions.userGroup.userGroupDefaults.defaultDimensions, allDimensions);

            this.addObservable('tempDataControls', {
                dimensionFilter: validatedDims,
                granularity: 'WEEKLY',
                startDate: monday13WeeksAgo.getFullYear() + '-' + prevMonth + '-' + prevDay,
                endDate: lastSunday.getFullYear() + '-' + month + '-' + endDay,
                homeChannel: [{'level': 1, 'attribute': 'CLIENT', 'value': client}],
                currentChannel: [{'level': 1, 'attribute': 'CLIENT', 'value': client}],
            });
        });
    }

    validatedDimensionOptions(src, ref) {
        const srcDupe = _map(src, srcDim => {
            const opts = _intersection(srcDim.options || [], _find(ref, ['dimension', srcDim.dimension])?.options);
            if (opts.length > 0) {
                return {dimension: srcDim.dimension, options: opts};
            }
            return;
        });
        return _without(srcDupe, undefined);
    }

    getState(key, appInit = false) {
        if (this.stateNotSet()) {
            if (this.gettingStateInProgress) { // so we are already getting the state somewhere else
                this.data[key] = new BehaviorSubject<any>(null);
                return appInit ? this.data[key].asObservable() : this.data[key].pipe(filter(result => result !== null));
            } else { // we arent getting the state yet and need to fetch it and fill it
                this.gettingStateInProgress = true;
                return this.http.get(environment.api_url + 'basics/userclientconfig').pipe(
                    switchMap((data: UserClientConfig) => {
                        this.setInitialState(data);
                        this.gettingStateInProgress = false;
                        return this.data[key].asObservable();
                    }),
                    catchError(() => {
                        // return empty Observable. the client will get state on next successful request
                        this.data[key] = new BehaviorSubject<any>(null);
                        return appInit ? this.data[key].asObservable() : this.data[key].pipe(filter(result => result !== null));
                    }));
            }

        } else { // so something at least is set, just check if its the particular key we want otherwise add it and return it with a filter

            if (!this.data[key]) {
                this.data[key] = new BehaviorSubject<any>(null);
            }
            return appInit ? this.data[key].asObservable() : this.data[key].pipe(filter(result => result !== null));
        }
    }

    stateNotSet() {
        return Object.keys(this.data).length === 0;
    }

    updateState(key, value) {
        this.updateData(key, value);
    }

    deleteState(key) {
        delete this.data[key];
    }

    startRenewSession() {
        this.ngZone.runOutsideAngular(() => {
            this.sessionInterval = setInterval(() => {
                this.http.post(environment.api_url + 'basics/renewSession', null, {responseType: 'text'}).subscribe(data => {
                    // do nothing
                });
            }, this.SESSION_RENEW_TIME);
        });
    }

    stopRenewSession() {
        clearInterval(this.sessionInterval);
    }

    getCurrentClientConfig(): Observable<ClientConfiguration> {
        this.client = this.locationService.getClient();
        return this.http.get(environment.api_url + 'basics/userclientconfig').pipe(
            switchMap((userclientconfig: UserClientConfig) => {
                this.gettingStateInProgress = false;
                return of(userclientconfig.clientConfigurationList.filter(clientConfig => clientConfig.client === this.client)[0]);
            }));
    }

    private permissionsForClient(data, client) {
        const keys = Object.keys(data.user.userGroups);
        for (const i of keys) {
            if (data.user.userGroups[i].userGroup.client === client) {
                this.addObservable('permissions', data.user.userGroups[i]);
                return data.user.userGroups[i];
            }
        }
    }

    private updateData(key, value) {
        this.data[key].next(this.serialize(value));
    }

    private addObservable(key, value) {
        if (this.data[key]) {
            this.updateData(key, value);
        } else {
            this.data[key] = new BehaviorSubject(value);
        }
    }

    private serialize = (data) => JSON.parse(JSON.stringify(data));
}
