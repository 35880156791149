import 'reflect-metadata';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isoWeek from 'dayjs/plugin/isoWeek';
import minMax from 'dayjs/plugin/minMax';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import {BaseInjector} from '@/app/services/base-injector.service';
import {defineCustomElements} from '@revolist/revogrid/loader';

const serverErrorsRegex = new RegExp(
    `0 Unknown Error|401 OK|401 Unauthorized|403 Forbidden`,
    'mi',
);

if (environment.production) {
    enableProdMode();

    Sentry.init({
        dsn: 'https://07037abebf52ec913f944353fb79f3a7@sentry-prod.objectiveplatform.com/5',
        environment: environment.env,
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            new Sentry.BrowserTracing({
                tracePropagationTargets: [environment.api_url],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),

            // Registers the Replay integration,
            // which automatically captures Session Replays
            new Sentry.Replay(),
        ],
        /**
         * The release identifier used when uploading respective source maps. Specify
         * this value to allow Sentry to resolve the correct source maps when
         * processing events.
         */
        // release: environment.sentryReleaseVersion,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: [serverErrorsRegex],
    });
}

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isoWeek);
dayjs.extend(minMax);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(weekday);
dayjs.extend(isBetween);

const JsonPlugin = (option, dayjsClass, dayjsFactory) => {
    // overriding existing API
    dayjsClass.prototype.toJSON = function() {
        return this.format('YYYY-MM-DDTHH:mm:ss');
    };
};
dayjs.extend(JsonPlugin);

platformBrowserDynamic().bootstrapModule(AppModule).then((moduleRef) => {
    BaseInjector.setInjector(moduleRef.injector);
}).catch((err) => console.error(err));
defineCustomElements();