<div container="body" ngbDropdown placement="bottom-right">
    <div class="bell-content" ngbDropdownToggle>
        <mat-icon class="material-icons-outlined bell">notifications</mat-icon>
        @if ((state | async).totalUnread > 0) {
            <div class="ml-3 count-chip"></div>
        }
    </div>
    <div class="content" ngbDropdownMenu>
        <div class="flex-justify-between content-title">
            <span class="pt-sm-2">
                <b class="title">Notifications</b>
                <mat-icon (click)="openNotificationPage()" class="open-icon" title="Open notifications page">open_in_full</mat-icon>
            </span>
            <button (click)="markAllAsRead()" class="pt-sm-2 all-read-button">
                <mat-icon>done_all</mat-icon>
                <span class="ml-10">Mark all as read</span>
            </button>
        </div>
        <div class="content-body">
            @if ((state | async).popupData.length === 0) {
                <div class="text-center">
                    <img height="300" src="assets/bell.jpg" width="300">
                    <h3>No notifications yet</h3>
                </div>
            }
            <div (click)="openNotification(item)"
                 *ngFor="let item of ((state | async).popupData)"
                 [ngClass]="{'unread-color' : !item.readDate}"
                 class="cursor-pointer list-notifications"
                 ngbDropdownItem>
                <div class="flex-justify-between">
                    <div [ngClass]="{'read-title' : !!item.readDate, 'title-text' : !item.readDate}">
                        <mat-icon *ngIf="item.type === 'MAINTENANCE'" class="title-icon">settings</mat-icon>
                        <mat-icon *ngIf="item.type === 'OPPIE_OPTIMIZATION'" class="title-icon">smart_toy</mat-icon>
                        <mat-icon *ngIf="item.type === 'MODEL_OPTIMIZATION'" class="title-icon">trending_up</mat-icon>
                        <mat-icon *ngIf="item.type === 'OTHER'" class="title-icon">circle_notifications</mat-icon>
                        <mat-icon *ngIf="item.type === 'RELEASE'" class="title-icon">new_releases</mat-icon>
                        <mat-icon *ngIf="item.type === 'INCIDENT'" class="title-icon">local_fire_department</mat-icon>

                        <b>{{ item.title }}</b>
                    </div>
                    <div>
                        <span *ngIf="!item.readDate" class="unread-dot"></span>
                    </div>
                </div>
                <div class="scheduled" title="{{item.scheduledDate | datetimeFormat: true}}">
                    {{ item.scheduledDate | dateAgo }}
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-item="item">
    <div>

    </div>
</ng-template>