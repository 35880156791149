import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {OppieEvent} from '../entities/OppieEvent';

@Injectable({
    providedIn: 'root',
})
export class OppieEventsStreamService {

    private _eventsStream = new Subject<OppieEvent>();

    get eventsStream() {
        return this._eventsStream.asObservable();
    }

    publishEvent(event: OppieEvent) {
        this._eventsStream.next(event);
    }
}
