<ng-template #hasChildren>
    <div [ngClass]="{'active': menuItem.isActive}" class="menu-item__wrapper">
        <div (click)="toggleSubMenu()" class="menu-item" id="parent_{{menuItem.title|idFormatter}}">
            <mat-icon *ngIf="menuItem.icon !== 'facebook'">{{ menuItem.icon }}</mat-icon>
            <mat-icon *ngIf="menuItem.icon === 'facebook'" svgIcon="op_facebook"></mat-icon>
            <span class="menu-item__title">{{ menuItem.title }}</span>
        </div>

        <ul [class.show]="!submenuIsHidden" [style.display]="!menuIsHidden ? 'block' : 'none'" class="menu-item__list">
            <li *ngFor="let child of menuItem.children" routerLinkActive="active">
                <a *ngIf="child.allowed" [routerLink]="child.url" class="menu-item__link"
                   id="child_{{child.title|idFormatter}}"> {{ child.title }} </a>
            </li>
        </ul>
    </div>
</ng-template>


<ng-template #hasNoChildren>
    <div class="menu-item__wrapper">
        <div class="menu-item">
            <a [ngClass]="{'active': menuItem.isActive}" [routerLink]="menuItem.url"
               class="menu-item__link" id="{{menuItem.title| idFormatter}}" routerLinkActive="active">
                <mat-icon>{{ menuItem.icon }}</mat-icon>
                <span class="menu-item__title">{{ menuItem.title }}</span>
            </a>
        </div>
    </div>
</ng-template>

<ng-container
        *ngTemplateOutlet="menuItem?.children && menuItem?.children?.length ? hasChildren : hasNoChildren"></ng-container>
