import {Component, OnInit} from '@angular/core';
import {InsightsIdentityService} from '../../services/insights-identity.service';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {

    impersonatingUsername;

    constructor(private insightsIdentityService: InsightsIdentityService) {
    }

    ngOnInit(): void {
        this.impersonatingUsername = localStorage.getItem('impersonated_fullName');
    }

    stopImpersonating() {
        this.insightsIdentityService.switchUserBack();
    }
}
