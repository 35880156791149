import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpParameterCodec, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LocationService} from '@/app/services/location.service';

@Injectable({
    providedIn: 'root'
})
export class CustomHttpParameterEncoder implements HttpInterceptor, HttpParameterCodec {

    constructor(private locationService: LocationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // ignore request for limited URLs
        if (this.isURLIgnoredForAttributeLevels(req)) {
            return next.handle(req);
        }

        let reqParams = req.params;
        let newBody: HttpParams;

        const clientFromUrl = this.locationService.getClient();
        const clientFromMemory = localStorage.getItem('selected_client');
        const client = clientFromUrl ? clientFromUrl : clientFromMemory;
        const attributeLevels = client ? JSON.stringify([{'level': 1, 'attribute': 'CLIENT', 'value': client}]) : null;

        // POST and PUT http methods
        if (req.body && req.body instanceof HttpParams) {
            let params = req.body;
            // only add attributeLevels if not present
            if (attributeLevels && !req.body.has('attributeLevels')) {
                params = params.set('attributeLevels', attributeLevels);
            }
            newBody = new HttpParams({
                fromString: params.toString(),
                encoder: this,
            });
        } else {
            // GET and DELETE http methods
            // only add attributeLevels if not present
            if (attributeLevels && !reqParams.has('attributeLevels')) {
                reqParams = reqParams.set('attributeLevels', attributeLevels);
            }
        }

        const newParams = new HttpParams({
            fromString: reqParams.toString(),
            encoder: this,
        });

        return next.handle(req.clone({
            params: newParams,
            body: newBody ? newBody : req.body
        }));
    }

    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }

    /**
     * Should ignore the URL for attributeLevels
     *
     * list of ignored endpoints
     * - GET - basics/userclientconfig
     * @param req
     * @private
     */
    private isURLIgnoredForAttributeLevels(req: HttpRequest<any>) {
        return req.url.includes('basics/userclientconfig');
    }
}
