import {Injectable} from '@angular/core';
import {ApplicationStateService} from '@/app/services/application-state.service';
import {environment} from '@/environments/environment';
import {Store} from '@ngrx/store';
import * as froomRoot from '@/app/reducers';
import {HttpXsrfTokenExtractor} from '@angular/common/http';
import {fetchEventSource} from '@microsoft/fetch-event-source';
import {receivedStopUserNotification, receivedUserNotification} from '@/app/reducers/notification/user-notification/user-notification.action';
import {reloadScheduledNotification} from '@/app/reducers/notification/scheduled-notification/scheduled-notification.action';

@Injectable({
    providedIn: 'root',
})
export class NotificationSseService {

    private controller: AbortController;

    constructor(
        private applicationState: ApplicationStateService,
        private tokenExtractor: HttpXsrfTokenExtractor,
        private store: Store<froomRoot.State>) {
    }

    public subscribeUserNotifications(): void {
        const token = this.tokenExtractor.getToken() as string;
        const user = this.applicationState.getUser();
        const tabId = this.generateTabId();
        const attributeLevels = JSON.stringify([{'level': 1, 'attribute': 'CLIENT', 'value': 'Demo'}]);
        const url = environment.api_url + 'notifications/subscribe?deviceId=' + tabId + '&attributeLevels=' +
            encodeURIComponent(attributeLevels.toString());
        this.controller = new AbortController();
        const signal = this.controller.signal;

        void fetchEventSource(
            url, {
                headers: {
                    'X-XSRF-TOKEN': token,
                    'Cookie': 'XSRF-TOKEN=' + token + ';',
                },
                credentials: 'include',
                openWhenHidden: true,
                onmessage: async (msg) => {
                    const data = JSON.parse(msg.data);
                    if (msg.event === 'NOTIFICATION') {
                        this.store.dispatch(receivedUserNotification({data}));
                        this.store.dispatch(reloadScheduledNotification());
                    } else if (msg.event === 'STOP-NOTIFICATION') {
                        this.store.dispatch(receivedStopUserNotification({data}));
                    }
                },
                onerror: (err) => {
                    throw Error('Error in connect to SSE');
                },
                signal,
            });
    }

    public unsubscribeUserNotifications(): void {
        this.controller.abort();
    }

    private generateTabId(): string {
        const tabID = sessionStorage.tabID &&
        sessionStorage.closedLastTab !== '2' ?
            sessionStorage.tabID :
            sessionStorage.tabID = Math.random();
        sessionStorage.closedLastTab = '2';
        return tabID;
    }
}

