export const highchartColors = [
    '#71ADC2',
    '#18A0EB',
    '#003F5C',
    '#2F4B7C',
    '#665191',
    '#A05195',
    '#F95D6A',
    '#D45087',
    '#FF7C43',
    '#FFA600',
    '#D8A700',
    '#B3A409',
    '#739833',
    '#41844C',
    '#1F4A27',
    '#121412',
];
