import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OppieLoadingComponent} from '@/app/components/oppie-loading/oppie-loading.component';



@NgModule({
  declarations: [OppieLoadingComponent],
  imports: [
    CommonModule
  ],
  exports: [OppieLoadingComponent]
})
export class OppieLoadingModule { }
