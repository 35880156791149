import {Injectable} from '@angular/core';
import {FilterData} from './FilterData';
import _ from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class MatFilterService {

    filters = {};

    areFiltersEmpty() {
        return Object.keys(this.filters).length === 0;
    }

    mergeFilters(filter: FilterData): any {
        if (!filter) {
            return;
        }
        if (filter.filtered) {
            this.filters[filter.column] = filter;
        } else {
            delete this.filters[filter.column];
        }
        const _filters = Object.keys(this.filters).map(key => this.filters[key]);
        return (data) => {
            let result = true;
            let predicate = true;
            for (const f of _filters) {
                let value = this.getValue(f.column, data);
                switch (f.type) {
                    case 'text':
                        if (_.isNumeric(value)) {
                            value = value.toString();
                        }
                        if (Array.isArray(value)) {
                            value = value.join('');
                        }
                        if (value !== null && typeof value === 'object') {
                            value = JSON.stringify(value);
                        }
                        predicate = value.toLowerCase().includes(f.text.toLowerCase());
                        break;
                    case 'date':
                        predicate = (f.fromDate == null ? true :
                                new Date(value).setHours(0, 0, 0, 0) >= new Date(f.fromDate).setHours(0, 0, 0, 0)) &&
                            (f.toDate == null ? true :
                                new Date(value).setHours(0, 0, 0, 0) <= new Date(f.toDate).setHours(0, 0, 0, 0));
                        break;
                }
                result = result && predicate;
            }
            return result;
        };
    }

    private getValue(path, obj) {
        return path.split('.').reduce(function (prev, curr) {
            return prev ? prev[curr] : null;
        }, obj || self);
    }
}
