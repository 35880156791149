import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {ScheduledNotificationState, UserNotificationState} from '@/app/reducers/notification/notification.interface';
import {scheduledNotificationReducer} from '@/app/reducers/notification/scheduled-notification/scheduled-notification.reducer';
import {userNotificationReducer} from '@/app/reducers/notification/user-notification/user-notification.reducer';

export interface State {
    scheduledNotification: ScheduledNotificationState;
    userNotification: UserNotificationState;
}

export const reducers: ActionReducerMap<State> = {
    scheduledNotification: scheduledNotificationReducer,
    userNotification: userNotificationReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export interface Loadable {
    loading?: boolean;
}

