import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {UserNotificationState} from '@/app/reducers/notification/notification.interface';
import {Store} from '@ngrx/store';
import * as froomRoot from '@/app/reducers';
import {Router} from '@angular/router';
import {readAllUserNotification, refreshTimerUserNotifications} from '@/app/reducers/notification/user-notification/user-notification.action';

@Component({
    selector: 'app-notification-popup',
    templateUrl: './notification-popup.component.html',
    styleUrls: ['./notification-popup.component.scss'],
})
export class NotificationPopupComponent implements OnInit {

    public state: Observable<UserNotificationState>;

    constructor(
        private store: Store<froomRoot.State>,
        private router: Router) {
        this.state = store.select('userNotification');
    }

    ngOnInit(): void {
        setInterval(() => {
            this.store.dispatch(refreshTimerUserNotifications());
        }, 60000);
    }

    markAllAsRead() {
        this.store.dispatch(readAllUserNotification({label: 'ALL'}));
    }

    openNotificationPage() {
        void this.router.navigateByUrl('/dashboard/notifications');
    }

    openNotification(item: any) {
        void this.router.navigateByUrl('/dashboard/notifications?selected=' + item.id);
    }
}
