import {Injectable} from '@angular/core';
import {ApplicationStateService} from './application-state.service';
import {first} from 'rxjs/operators';
import {TranslationService} from './translation.service';
import {KeyPerformanceIndicator} from '../entities/keyPerformanceIndicator';
import {NotificationService} from './notification.service';
import zipcelx from 'zipcelx';
import {highchartColors} from '../entities/HighchartColors';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@/environments/environment';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import Highcharts from 'highcharts';
import {DateformatService} from '@/app/services/dateformat.service';
import {OpNumberPipe} from '@/app/pipes/op-number.pipe';
import {StyleKpiPipe} from '@/app/pipes/style-kpi.pipe';

dayjs.extend(CustomParseFormat);

@Injectable({
    providedIn: 'root'
})
export class ChartdataTransformationService {
    private MAX_ROWS_TOOLTIP = 20;
    private colors = [];
    private granularity;
    private seriesVisibility;
    private weekdaySorter = {'Mon': 1, 'Tue': 2, 'Wed': 3, 'Thu': 4, 'Fri': 5, 'Sat': 6, 'Sun': 7};

    constructor(private http: HttpClient,
                private stateObject: ApplicationStateService,
                protected opNumberPipe: OpNumberPipe,
                protected styleKpiPipe: StyleKpiPipe,
                protected notificationService: NotificationService,
                private dateFormatService: DateformatService
    ) {
        this.stateObject.getState('clientConfiguration').pipe(first()).subscribe(clientConfiguration => {
            this.colors = clientConfiguration.colors;
        });
        this.stateObject.getState('seriesVisibility').subscribe(seriesVisibility => {
            this.seriesVisibility = seriesVisibility;
        });
    }

    /**
     * getKpis retrieves the channel data from the api based on the given chartcontrols
     * @param chartControls
     * @param availableKpis
     */
    getKpis(chartControls: any, availableKpis: KeyPerformanceIndicator[]): Promise<any> {
        return new Promise<any>(resolve => {
            this.granularity = chartControls.granularity;

            let params = new HttpParams();
            params = params.append('startDate', chartControls.startDate);
            params = params.append('endDate', chartControls.endDate);
            params = params.append('attributeLevels', chartControls.currentChannel
                ? JSON.stringify(chartControls.currentChannel)
                : JSON.stringify(chartControls.homeChannel));
            params = params.append('granularity', chartControls.granularity);
            params = params.append('dimensionFilters', chartControls.dimensionFilter ?
                JSON.stringify(chartControls.dimensionFilter) : JSON.stringify([]));

            this.stateObject.updateState('dataControlsDisabled', true);
            this.http.get(environment.api_url + 'channel', {params: params}).subscribe(
                (data) => {
                    this.stateObject.updateState('dataControlsDisabled', false);
                    resolve(this.handleKpiResponse(data, availableKpis));
                });
        });
    }

    handleKpiResponse(data: Object, availableKpis: KeyPerformanceIndicator[]) {
        return this.addTotals(data, availableKpis);
    }

    /**
     * Returns the options of a highcharts chart
     */
    getOptions() {
        return {
            credits: false,
            chart: {
                type: undefined,
                plotBorderWidth: 1,
                zoomType: 'x',
                polar: false,
                background: false,
                height: undefined
            },
            title: false,
            height: undefined,
            subtitle: false,
            colors: this.colors.length === 0 ? highchartColors : this.colors,
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadPDF', 'separator', 'downloadCSV', 'downloadXLS']
                    }
                },
                menuItemDefinitions: {},
                chartOptions: {
                    title: false
                }
            },
            legend: undefined,
            xAxis: undefined,
            yAxis: undefined,
            series: [],
            tooltip: {},
            plotOptions: undefined
        };
    }

    /**
     * Transforms the data to the chartoptions needed for the specified chartType
     * @param chartType
     * @param data
     */
    transformData(chartType, data) {
        if (chartType === 'line' || chartType === 'stacked' || chartType === 'percentage_area') {
            return this.transformTimeSeriesToLine(data, chartType);
        } else if (chartType === 'column') {
            return this.transformPanelToColumn(data);
        } else {
            throw Error('This data transformation is not implemented yet:  to ' + chartType);
        }
    }

    /**
     * Sort the data by the currently selected kpi
     * @param data
     */
    sortData(data) {
        const dataToSort = Object.assign([], data.data);

        // get the currently selected KPI from the metric, and sort our data on that property
        const sortProperty = data.metadata.metricY;
        return dataToSort.sort((a, b) => b[sortProperty] - a[sortProperty]);
    }

    /**
     * Group the data on model name
     * @param data
     */
    groupByModel(data) {
        const models = data.reduce((accumulator, currentValue) => {
                // if record already exists push the value into the data array
                // otherwise it will be added as a new record to the accumulator
                for (let i = 0; i < accumulator.length; i++) {
                    if (accumulator[i].channelName === TranslationService.translate(currentValue.modelName)) {
                        accumulator[i].data.push(currentValue);

                        return accumulator;
                    }
                }

                accumulator.push(
                    {
                        channelName: TranslationService.translate(currentValue.modelName),
                        data: [currentValue],
                        hasChildren: currentValue.hasChildren
                    });

                return accumulator;
            }, []
        );

        for (let i = 0; i < models.length; i++) {
            models[i].data = this.flattenChannels([], models[i].data);
        }

        return models;
    }

    /**
     * Aggregate the data by attributeLevel
     * @param data
     */
    groupByChannel(data) {
        return data.reduce((accumulator, currentValue) => {
                for (let i = 0; i < accumulator.length; i++) {
                    if (accumulator[i].channelName === currentValue.attributeLevels.value) {
                        accumulator[i].data.push(currentValue);

                        return accumulator;
                    }
                }

                accumulator.push(
                    {
                        channelName: currentValue.attributeLevels.value,
                        hasChildren: currentValue.hasChildren,
                        data: [currentValue],
                        attributeLevel: currentValue.attributeLevels
                    });

                return accumulator;
            }, []
        );
    }

    /**
     * Aggregate the data per KPI
     * @param keyPerformanceIndicators
     * @param data
     */
    groupByKpi(keyPerformanceIndicators, data) {
        const accumulator = [];

        // create a record for every KPI available with an empty list to store the data
        for (let i = 0; i < keyPerformanceIndicators.length; i++) {
            accumulator.push({metric: keyPerformanceIndicators[i], data: []});
        }

        // From every data point get all the KPI properties.
        // Store these in the accumulator with the corresponding KPI
        for (let j = 0; j < data.length; j++) {
            for (let k = 0; k < accumulator.length; k++) {
                const kpiPropertyName = accumulator[k].metric.propertyName;

                accumulator[k].data.push({period: data[j].period, value: data[j][kpiPropertyName]});
            }
        }

        return accumulator;
    }

    /**
     * Aggregrate a kpi based on the timeperiod
     * @param valuesPerPeriod
     * @param availableKpis
     */
    flattenPeriods(valuesPerPeriod, availableKpis: KeyPerformanceIndicator[]) {
        return valuesPerPeriod.reduce((accumulator, currentValue) => {
            // check all accumulators if any of them have the same period as our current point
            for (let i = 0; i < accumulator.length; i++) {
                if (accumulator[i].period === currentValue.label) {
                    // if they have the same value, sum all their kpi data
                    availableKpis.forEach(kpi => {
                        accumulator[i][kpi.propertyName] += currentValue[kpi.propertyName];
                    });

                    return accumulator;
                }
            }
            const pointToAdd = {period: currentValue.label};
            availableKpis.forEach(kpi => {
                pointToAdd[kpi.propertyName] = currentValue[kpi.propertyName];
            });

            accumulator.push(pointToAdd);
            return accumulator;
        }, []);
    }

    /**
     * Aggregrate the conversions and revenue based on the attributeLevel
     * @param kpis
     * @param data
     */
    flattenChannels(kpis, data) {
        return data.reduce((accumulator, currentValue) => {
            for (let i = 0; i < accumulator.length; i++) {
                if (accumulator[i].attributeLevels.value === currentValue.attributeLevels.value) {
                    accumulator[i].conversions += currentValue.conversions;
                    accumulator[i].revenue += currentValue.revenue;

                    return accumulator;
                }
            }

            accumulator.push(currentValue);

            return accumulator;
        }, []);
    }

    calculateTotals(dataObject) {
        const array = {};
        let nominatorTemp = 0;
        let denominatorTemp = 0;
        let totalsCellData = {nominator: 0, denominator: 0};
        const data = dataObject.data;
        for (let i = 0; i < data.length; i++) {
            let arrayKey;
            if (data[i].modelName != null) {
                arrayKey = data[i].attributeLevels.value;
            } else {
                data[i].label = this.formatDatelabel(data[i].label);
                arrayKey = data[i].label;

                const seriesIsVisible = this.seriesIsVisible(data[i].attributeLevels.value, dataObject.metadata.seriesVisibility);
                if (!seriesIsVisible) {
                    continue;
                }
            }
            if (dataObject.metadata.keyPerformanceIndicator.calculationDenominator != null) {
                nominatorTemp = data[i][dataObject.metadata.keyPerformanceIndicator.calculationNominator.propertyName];
                denominatorTemp = data[i][dataObject.metadata.keyPerformanceIndicator.calculationDenominator.propertyName];
            } else {
                nominatorTemp = data[i][dataObject.metadata.keyPerformanceIndicator.propertyName] ?
                    data[i][dataObject.metadata.keyPerformanceIndicator.propertyName] : 0;
                denominatorTemp = 1;
            }
            if (array[arrayKey] == null) {
                totalsCellData = {nominator: nominatorTemp, denominator: denominatorTemp};
            } else {
                nominatorTemp = array[arrayKey].nominator + nominatorTemp;
                denominatorTemp = dataObject.metadata.keyPerformanceIndicator.calculationDenominator == null ?
                    1 : (array[arrayKey].denominator + denominatorTemp);
                totalsCellData = {nominator: nominatorTemp, denominator: denominatorTemp};
            }
            array[arrayKey] = totalsCellData;
        }

        Object.keys(array).forEach((rowLabel) => {
            const cell = array[rowLabel];
            if (cell.nominator != null) {
                const totalValue = cell.nominator / cell.denominator;
                if (isFinite(totalValue)) {
                    array[rowLabel] = cell.nominator / cell.denominator;
                } else {
                    array[rowLabel] = 0;
                }
            }
        });

        return array;
    }

    seriesIsVisible(seriesName: string, seriesVisibility): boolean {
        for (let i = 0; i < seriesVisibility.length; i++) {
            if (seriesVisibility[i].value && seriesVisibility[i].name.toLowerCase() === seriesName.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    drillDown(evt, data, channelPath) {
        if (evt != null) {
            if (evt.point.series.userOptions.hasChildren !== undefined && !evt.point.series.userOptions.hasChildren) {
                this.notificationService.info('You have reached the deepest available channel level for this selection, a more detailed view is not available.', 'No lower levels available');
                return;
            } else {
                evt.point.series.chart.showLoading();
            }
        }
        this.stateObject.getState('tempDataControls').pipe(first()).subscribe(dataControls => {
            if (evt != null) {
                if (data.metadata.attributeLevelsInData) {
                    dataControls.currentChannel.push(evt.point.attributeLevel);
                } else {
                    dataControls.currentChannel.push(evt.point.series.userOptions.channelPath);
                }
            } else {
                dataControls.currentChannel.push(channelPath);
            }

            this.stateObject.updateState('tempDataControls', dataControls);
        });
    }

    public formatAndSortTimeSeries(categories: string[], granularity: string) {
        switch (granularity.toUpperCase()) {
            case 'DAILY' :
            case 'DATE' :
            case 'WEEKLY' : {
                return categories.map(
                    entry => {
                        return dayjs(entry, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('DD/MM/YY');
                    },
                ).sort((a, b) => {
                    return dayjs(a, 'DD/MM/YY').isAfter(dayjs(b, 'DD/MM/YY')) ? 1 : -1;
                });
            }
            case 'WEEK' : {
                return categories.sort((a, b) => {
                    return this.dateFormatService.compareTwoFormattedWeekDates(a, b);
                });
            }
            case 'MONTH' : {
                return categories.sort((a, b) => {
                    return dayjs(a, 'MMM YYYY').isAfter(dayjs(b, 'MMM YYYY')) ? 1 : -1;
                });
            }
            case 'QUARTER' : {
                return categories.sort((a, b) => {
                    return this.dateFormatService.compareTwoFormattedQuarterDates(a, b);
                });
            }
            case 'YEAR' : {
                return categories.sort((a, b) => {
                    return Number(a) < Number(b) ? -1 : 1;
                });
            }
            case 'WEEKDAY' : {
                return categories.sort((a, b) => {
                    return this.weekdaySorter[a] < this.weekdaySorter[b] ? -1 : 1;
                });
            }
            default: { // Our graph did no plot data on a time based manner, so we simply sort our categories alphabetically
                return categories.sort((a, b) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });
            }
        }
    }

    /**
     * return timeSeries categories (x - axis categories) depending on the data format
     * @param data
     */
    private timeSeriesCategories(data) {
        if (data.metadata.attributeLevelsInData) {
            return Array.from(new Set(data.data.map(value => value.attributeLevels.value)));
        } else {
            // should use the ... operator however not supported properly yet by typescript
            return Array.from(new Set(data.data.map(value => {
                return this.formatDatelabel(value.label);
            })));
        }
    }

    /**
     * Generate the tooltip with a total at the bottom. This total is calculated depending on the Key performance indicator shown     *
     * @return tooltip html with a way to calculate this kpis total value
     */
    private getTooltip(keyPerformanceIndicator: KeyPerformanceIndicator, totalsArray) {
        const maxRows = this.MAX_ROWS_TOOLTIP;
        const styleKpiPipe = this.styleKpiPipe;

        return {
            shared: true,
            formatter: function () {
                // For security reasons, Highcharts since version 9 filters out unknown tags and attributes
                // This will allow to Highcharts to render unknown tags.
                Highcharts.AST.allowedAttributes.push('data-cy');

                let s = '<b>' + this.x + '</b>';
                this.points.forEach((point, i) => {
                    if (i <= maxRows) {
                        s += '<br/><span style="font-weight:bold; color: '
                            + point.color
                            + '">' + point.series.name + ': '
                            + '</span>'
                            + `<span data-cy=${point.series.name}>` + styleKpiPipe.transform(point.y, keyPerformanceIndicator)
                            + '</span>';
                    }
                });
                if (this.points.length > maxRows + 1) {
                    const rowsLeft = this.points.length - 1 - maxRows;
                    s += '<br/><b>' + rowsLeft + ' more</b>';
                }
                const total = totalsArray[this.x];
                s += '<br/><b>Total</b>: '
                    + '<span data-cy="total">'
                    + styleKpiPipe.transform(total, keyPerformanceIndicator);
                +'</span>';

                return s;
            },
        };
    }

    private formatDatelabel(key: string) {
        const isDateYYYYMMDD = dayjs(key, 'YYYY-MM-DD', true).isValid();
        if (isDateYYYYMMDD) {
            return dayjs(key).format('DD/MM/YYYY');
        }
        return key;
    }

    /**
     * Transforms time series data to line charts (single, kpi comparison or time comparison)
     * @param data {data.data : [TimeSeriesData], metadata: {metricOne: String, metricTwo: String}}
     * @return chart config options
     */
    private transformTimeSeriesToLine(data, type) {
        const options = this.getOptions();

        const totalsArray = this.calculateTotals(data);
        options.tooltip = this.getTooltip(data.metadata.keyPerformanceIndicator, totalsArray);

        if (type === 'stacked' || type === 'percentage_area') {
            options.chart.type = 'area';
        } else {
            options.chart.type = 'line';
        }

        // don't stack calculated metrics
        if (data.metadata.keyPerformanceIndicator.calculationDenominator) {
            options.chart.type = 'line';
        }

        const xAxisCategories = this.timeSeriesCategories(data);

        options.xAxis = {
            tickInterval: Math.floor(xAxisCategories.length / 10),
            categories: xAxisCategories,
            title: {
                text: ''
            }
        };

        options.legend = {enabled: true};
        delete (options.plotOptions);

        options.plotOptions = {
            series: {
                events: {
                    legendItemClick: evt => {
                        const index = this.seriesVisibility.findIndex(serie => serie.name.toLowerCase() === evt.target.name.toLowerCase());
                        this.seriesVisibility[index] = {name: evt.target.name, value: !evt.target.visible};
                        this.stateObject.updateState('seriesVisibility', this.seriesVisibility);
                        return false;
                    }
                }
            }
        };

        if (type !== 'line') {
            options.plotOptions.area = {
                stacking: type === 'percentage_area' ? 'percent' : 'normal',
                lineColor: '#666666',
                lineWidth: 1,
                marker: {
                    enabled: false,
                    lineWidth: 1,
                    lineColor: '#666666'
                }
            };
        }

        if (data.metadata.exporting) {
            options.exporting.enabled = true;
            options.exporting.buttons = {
                contextButton: {
                    menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadPDF', 'separator', 'downloadCSV', 'downloadXLSX']
                }
            };
            options.exporting.menuItemDefinitions = this.returnMenuDefConfig();
        }

        options.yAxis = [];
        options.series = [];
        options.yAxis.push(this.lineSeriesYAxis(data.metadata.metricY, {opposite: false}, data.metadata.keyPerformanceIndicator, type));

        this.groupByChannel(data.data).map(serie => {
            options.series.push({
                yAxis: 0,
                dashStyle: 'line',
                name: serie.channelName,
                data: this.getData(serie, data, xAxisCategories),
                channelPath: serie.attributeLevel,
                hasChildren: serie.hasChildren,
                visible: true
            });
        });

        this.removeEmptySeries(data.metadata.totals, options);
        this.setInitialVisibility(data.metadata.seriesVisibility, options);
        this.addLegendIndex(data.metadata.totals, options);

        return options;
    }

    /**
     * Determines if a channel should be set to visible or not in the graphs on initial loading
     * @param seriesVisibility
     * @param options
     */
    private setInitialVisibility(seriesVisibility, options) {
        options.series = options.series.map(serie => {
            const index = seriesVisibility.findIndex(serieVisibility => serieVisibility.name === serie.name);
            if (index > -1) {
                serie.visible = seriesVisibility[index].value;
            } else {
                serie.visible = true;
            }

            return serie;
        });
    }

    /**
     * Removes empty series where the totals are 0
     * @param totals
     * @param options
     */
    private removeEmptySeries(totals, options) {
        totals = totals.filter(total => total.totals !== 0);

        options.series = options.series.filter(series => {
            return totals.findIndex(total => total.label.toLowerCase() === series.name.toLowerCase()) > -1;
        });
    }

    /**
     * Returns the configuration for the chart y axis
     * @param title
     * @param parameters
     * @param keyPerformanceIndicator
     * @param chartType
     */
    private lineSeriesYAxis(title, parameters, keyPerformanceIndicator: KeyPerformanceIndicator, chartType?) {
        const usePercentageFormat = chartType === 'percentage_area' || keyPerformanceIndicator.percentage;

        return {
            title: {
                text: TranslationService.translate(title)
            },
            labels: {
                formatter: x => {
                    return this.styleKpiPipe.transform(x.value, keyPerformanceIndicator);
                }
            },
            opposite: parameters.opposite
        };
    }

    /**
     * Custom config code for adding the download xlsx functionality to the export button
     */
    private returnMenuDefConfig() {
        return {
            'downloadXLSX': {
                text: 'Download XLSX',
                onclick: function () {
                    const rows = this.getDataRows(true);
                    const xlsxRows = rows.slice(1).map((row) => {
                        return row.map(column => {
                            return {
                                type: typeof column === 'number' ? 'number' : 'string',
                                value: column
                            };
                        });
                    });

                    const configData = {
                        filename: 'chart',
                        sheet: {
                            data: xlsxRows
                        }
                    };

                    zipcelx(configData);
                }
            }
        };
    }

    /**
     * Transforms panel data to a column chart
     * @param data
     */
    private transformPanelToColumn(data) {
        const options = this.getOptions();
        options.chart.type = 'column';

        if (data.metadata.exporting) {
            options.exporting.enabled = true;
            options.exporting.buttons = {
                contextButton: {
                    menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadPDF', 'separator', 'downloadCSV', 'downloadXLSX']
                }
            };

            options.exporting.menuItemDefinitions = this.returnMenuDefConfig();
        }

        const xAxisCategories = this.timeSeriesCategories(data);

        data.data = this.sortData(data);


        // makes sure that all the labels are properly displayed (autorotation)
        options.xAxis = {
            categories: xAxisCategories,
            labels: {
                autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
                formatter: (label) => {
                    const isDateDDMMYYYY = dayjs(label.value, 'DD/MM/YYYY', true).isValid();
                    if (isDateDDMMYYYY) {
                        const dateParts = label.value.split('/');
                        const date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                        return dayjs(date).format('DD/MM/[\']YY');
                    }
                    return label.value;
                }
            },
            title: {
                text: ''
            }
        };
        const totalsArray = this.calculateTotals(data);
        options.tooltip = this.getTooltip(data.metadata.keyPerformanceIndicator, totalsArray);

        options.legend = {enabled: true};

        options.plotOptions = {
            series: {
                events: {
                    legendItemClick: evt => {
                        const index = this.seriesVisibility.findIndex(serie => serie.name.toLowerCase() === evt.target.name.toLowerCase());
                        this.seriesVisibility[index] = {name: evt.target.name, value: !evt.target.visible};
                        this.stateObject.updateState('seriesVisibility', this.seriesVisibility);
                        return false;
                    }
                }
            }
        };

        if (!data.metadata.disableDrilldown) {
            options.plotOptions.series.events.click = evt => this.drillDown(evt, data, null);
        }
        options.yAxis = [];
        options.series = [];
        options.yAxis.push(this.lineSeriesYAxis(data.metadata.metricY, {opposite: false}, data.metadata.keyPerformanceIndicator));

        let groupByChannel;

        if (data.metadata.attributeLevelsInData) {
            groupByChannel = this.groupByModel(data.data);
        } else {
            groupByChannel = this.groupByChannel(data.data);
        }

        if (data.metadata.sortLegends) {
            groupByChannel.sort((a, b) => a.channelName.toLowerCase().localeCompare(b.channelName.toLowerCase()));
        }

        groupByChannel.map(serie => {
            if (!data.metadata.attributeLevelsInData) {
                serie.data.sort(function (a, b) {
                    return xAxisCategories.indexOf(a.label) - xAxisCategories.indexOf(b.label);
                });
            } else {
                serie.data.sort(function (a, b) {
                    return xAxisCategories.indexOf(a.attributeLevels.value) - xAxisCategories.indexOf(b.attributeLevels.value);
                });
            }

            options.series.push({
                yAxis: 0,
                dashStyle: 'line',
                name: serie.channelName,
                data: this.getData(serie, data, xAxisCategories),
                channelPath: serie.attributeLevel,
                hasChildren: serie.hasChildren,
                visible: true,
                color: null
            });
        });

        if (!data.metadata.attributeLevelsInData) {
            this.removeEmptySeries(data.metadata.totals, options);
            this.setInitialVisibility(data.metadata.seriesVisibility, options);
            this.addLegendIndex(data.metadata.totals, options);
        }

        return options;
    }

    /**
     * Add a legend index and a color for consistency between graph changes
     * @param totals
     * @param options
     */
    private addLegendIndex(totals, options) {
        totals.sort(function (a, b) {
            return b[1] - a[1];
        });

        for (let i = 0; i < totals.length; i++) {
            const seriesIndex = options.series.findIndex(serie => serie.name.toLowerCase() === totals[i].label.toLowerCase());
            if (seriesIndex > -1) {
                options.series[seriesIndex].legendIndex = i + 1;
                options.series[seriesIndex].color = this.colors[seriesIndex];
            }
        }

        if (options.chart.type === 'area') {
            options.series.sort(function (a, b) {
                return b.legendIndex - a.legendIndex;
            });
        }
    }

    /**
     * Creates a dataobject for use with calculated and non calculated metrics
     * @param serie
     * @param data
     * @param xAxisCategories
     */
    private getData(serie, data, xAxisCategories) {
        return serie.data.map(dataPoint => {
            const dataObject = {
                y: dataPoint[data.metadata.metricY],
                denominator: null,
                nominator: null
            };

            if (data.metadata.keyPerformanceIndicator.calculationDenominator != null) {
                dataObject.denominator = dataPoint[data.metadata.keyPerformanceIndicator.calculationDenominator.propertyName];
            }

            if (data.metadata.keyPerformanceIndicator.calculationNominator != null) {
                dataObject.nominator = dataPoint[data.metadata.keyPerformanceIndicator.calculationNominator.propertyName];
            }

            if (dataObject.nominator == null && dataObject.denominator == null) {
                dataObject.nominator = dataObject.y;
            }

            if (data.metadata.attributeLevelsInData) {
                dataObject['attributeLevel'] = Object.assign({}, dataPoint.attributeLevels);
                dataObject['x'] = xAxisCategories.indexOf(dataPoint.attributeLevels.value);
            }

            return dataObject;
        });
    }

    private addTotals(aggregatedData: Object, availableKpis: KeyPerformanceIndicator[]) {
        const periodGroupedData = this.flattenPeriods(aggregatedData, availableKpis);

        // some KPIs can not be normally flattened by summing their values
        // if a KPI has a (de)nominator that is non null we need to do a calculation to overwrite the dataByKpi value for each period
        periodGroupedData.forEach(x => {
            availableKpis.filter(kpi => kpi.calculationNominator != null || kpi.calculationDenominator != null).forEach(kpi => {
                let nominator: string = null;
                let denominator: string = null;

                // check if a (de)nominator is specified for this kpi, if so set the value to that propertyName
                if (kpi.calculationDenominator != null) {
                    denominator = kpi.calculationDenominator.propertyName;
                }

                if (kpi.calculationNominator != null) {
                    nominator = kpi.calculationNominator.propertyName;
                }

                // get the values to calculate this field from the data
                // if they don't exist they are 1
                const nominatorValue = nominator != null ? x[nominator] : 1;
                const denominatorValue = denominator != null ? x[denominator] : 1;

                x[kpi.propertyName] = nominatorValue / denominatorValue;
            });
        });

        // group all data per kpi
        const dataByKpi = this.groupByKpi(availableKpis, periodGroupedData);

        const totals = {};
        // set the totals for all non-derived metrics
        dataByKpi
            .filter(kpi => kpi.metric.calculationNominator == null && kpi.metric.calculationDenominator == null)
            .forEach(dataForKpi => {
                totals[dataForKpi.metric.propertyName] = 0;

                dataForKpi.data.forEach(datapoint => {
                    if (datapoint.value) {
                        totals[dataForKpi.metric.propertyName] += datapoint.value;
                    }
                });
            });
        // set the totals for all derived metrics by using the calculated totals from the non-derived metrics
        dataByKpi
            .filter(kpi => kpi.metric.calculationNominator != null || kpi.metric.calculationDenominator != null)
            .forEach(dataForKpi => {
                const nominatorValue = dataForKpi.metric.calculationNominator != null ?
                    totals[dataForKpi.metric.calculationNominator.propertyName] : 1;
                const denominatorValue = dataForKpi.metric.calculationDenominator != null ?
                    totals[dataForKpi.metric.calculationDenominator.propertyName] : 1;
                totals[dataForKpi.metric.propertyName] = nominatorValue / denominatorValue;

            });

        return dataByKpi.map(dataForKpi => {
            dataForKpi.totals = {value: totals[dataForKpi.metric.propertyName]};
            return dataForKpi;
        });
    }
}
