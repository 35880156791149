import {createReducer, on} from '@ngrx/store';
import {UserNotificationState} from '../notification.interface';
import {
    failedUserNotifications,
    loadedUserNotifications,
    loadUserNotifications,
    readAllDoneUserNotification,
    readDoneUserNotification,
    receivedStopUserNotification,
    receivedUserNotification,
    refreshTimerUserNotifications,
    unreadDoneUserNotification,
} from './user-notification.action';
import _ from 'lodash-es';
import dayjs from 'dayjs';

export const initialState: UserNotificationState = {
    refreshTotals: false,
    data: [],
    popupData: [],
    totalUnread: 0,
    loading: false,
    selectedLabel: 'ALL',
};

export const userNotificationReducer = createReducer(
    initialState,
    on(loadUserNotifications, (state, props) => ({...state, loading: true})),
    on(loadedUserNotifications, (state, props) => {
        const popupData = props.data.filter(item => !item.readDate).slice(0, 5);
        if (popupData.length < 5) {
            popupData.push(...props.data.filter(item => !!item.readDate).slice(0, 5 - popupData.length));
        }
        return ({
            ...state,
            refreshTotals: true,
            data: props.data,
            loading: false,
            popupData,
            totalUnread: props.data.filter(item => !item.readDate).length,
            selectedLabel: 'ALL',
        });
    }),
    on(readDoneUserNotification, unreadDoneUserNotification, (state, props) => {
        const data = _.cloneDeep(state.data);
        const index = data.findIndex(item => item.id === props.data.id);
        data[index] = props.data;

        // update popup data
        const popupData = data.filter(item => !item.readDate).slice(0, 5);
        if (popupData.length < 5) {
            popupData.push(...data.filter(item => !!item.readDate).slice(0, 5 - popupData.length));
        }

        return ({
            ...state,
            refreshTotals: true,
            data,
            popupData,
            totalUnread: data.filter(item => !item.readDate).length,
            loading: false,
        });
    }),
    on(readAllDoneUserNotification, (state, props) => {
        const data = _.cloneDeep(state.data);
        // update all notifications of the selected type (label)
        data.forEach(item => {
            if ((item.type === props.label || props.label === 'ALL') && !item.readDate) {
                item.readDate = dayjs();
            }
        });

        // update popup data
        const popupData = data.filter(item => !item.readDate).slice(0, 5);
        if (popupData.length < 5) {
            popupData.push(...data.filter(item => !!item.readDate).slice(0, 5 - popupData.length));
        }

        return ({
            ...state,
            refreshTotals: true,
            data,
            popupData,
            totalUnread: data.filter(item => !item.readDate).length,
            loading: false,
        });
    }),
    on(receivedUserNotification, (state, props) => {
        const data = _.cloneDeep(state.data);
        data.unshift(props.data);

        const popupData = data.filter(item => !item.readDate).slice(0, 5);
        if (popupData.length < 5) {
            popupData.push(...data.filter(item => !!item.readDate).slice(0, 5 - popupData.length));
        }

        return ({
            ...state,
            refreshTotals: true,
            data,
            popupData,
            totalUnread: data.filter(item => !item.readDate).length,
        });
    }),
    on(receivedStopUserNotification, (state, props) => {
        const data = _.cloneDeep(state.data).filter(item => item.id !== props.data.id);

        const popupData = data.filter(item => !item.readDate).slice(0, 5);
        if (popupData.length < 5) {
            popupData.push(...data.filter(item => !!item.readDate).slice(0, 5 - popupData.length));
        }

        return ({
            ...state,
            refreshTotals: true,
            data,
            popupData,
            totalUnread: data.filter(item => !item.readDate).length,
        });
    }),
    on(failedUserNotifications, (state, props) => ({...state, loading: false})),
    on(refreshTimerUserNotifications, (state, props) => {
        const popupData = _.cloneDeep(state.popupData);
        return ({...state, popupData});
    }),
);