import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    // possible settings can be found here: https://www.npmjs.com/package/ngx-toastr
    config = {
        disableTimeOut: false,
        closeButton: true,
        timeOut: 3000,
    };

    configError = {
        disableTimeOut: false,
        closeButton: true,
        timeOut: 10000,
        extendedTimeOut: 10000,
    };

    configPermanent = {
        disableTimeOut: true,
        closeButton: true
    };

    constructor(protected toastr: ToastrService) {
    }

    /**
     * info functions show an info message with the input title and message
     * @param message
     * @param title
     */
    public info(message, title) {
        this.showNotification('info', title, message);
    }

    /**
     * info functions show an error message with the input title and message
     * @param message
     * @param title
     */
    public error(message, title, enableHtml = false) {
        this.showNotification('error', title, message, enableHtml);
    }

    /**
     * info functions show an error message with the input title and message
     * @param message
     * @param title
     */
    public errorPermanent(message, title, enableHtml = false) {
        this.showNotification('error', title, message, enableHtml, true);
    }

    /**
     * info functions show a success message with the input title and message
     * @param message
     * @param title
     */
    public success(message, title) {
        this.showNotification('success', title, message);
    }

    /**
     * info functions show a warning message with the input title and message
     * @param message
     * @param title
     */
    public warning(message, title) {
        this.showNotification('warning', title, message);
    }

    /**
     * showNotification shows the specific notification dependent on the type parameter
     * @param type
     * @param title
     * @param message
     */
    private showNotification(type: string, title: string, message: string, enableHtml = false, permanent = false) {
        let config: { disableTimeOut: boolean; closeButton: boolean } = type === 'success' ? this.config : this.configError;
        if (enableHtml) {
           config['enableHtml'] = true;
        }
        if (permanent) {
           config = this.configPermanent;
        }
        switch (type) {
            case 'info':
                this.toastr.info(message, title, config);
                break;
            case 'error':
                this.toastr.error(message, title, config);
                break;
            case 'warning':
                this.toastr.warning(message, title, config);
                break;
            case 'success':
                this.toastr.success(message, title, config);
                break;
        }
    }
}
