import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DateformatService} from '../services/dateformat.service';
import {Dayjs} from 'dayjs';

@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'datetimeFormat',
})
export class DatetimeFormatPipe implements PipeTransform {
    constructor(protected dateformatService: DateformatService) {
    }

    transform(value: string | Dayjs, referTime: boolean = false): string {
        let result = '';
        if (referTime) {
            result = this.dateformatService.getDateTimeReferFormatted(value);
        } else {
            result = this.dateformatService.formatDate(value, 'DD/MM/YYYY HH:mm:ss');
        }
        if (result === 'Invalid Date') {
            result = '-';
        }
        return result;
    }
}
