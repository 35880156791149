import {createAction, props} from '@ngrx/store';
import {Notification, UserNotification} from '../notification.interface';

export enum UserNotificationAction {
    USER_NOTIFICATION_LOAD = '[USER_NOTIFICATION] Load Action',
    USER_NOTIFICATION_LOADED = '[USER_NOTIFICATION] Loaded Action',
    USER_NOTIFICATION_READ = '[USER_NOTIFICATION] Read Action',
    USER_NOTIFICATION_READ_DONE = '[USER_NOTIFICATION] Read Done Action',
    USER_NOTIFICATION_READ_ALL = '[USER_NOTIFICATION] Read All Action',
    USER_NOTIFICATION_READ_ALL_DONE = '[USER_NOTIFICATION] Read All Done Action',
    USER_NOTIFICATION_UNREAD = '[USER_NOTIFICATION] Unread Action',
    USER_NOTIFICATION_UNREAD_DONE = '[USER_NOTIFICATION] Unread Done Action',
    USER_NOTIFICATION_RECEIVED = '[USER_NOTIFICATION] Notification Received Action',
    USER_NOTIFICATION_STOP_RECEIVED = '[USER_NOTIFICATION] Notification Stop Received Action',
    USER_NOTIFICATION_FAILED = '[USER_NOTIFICATION] Failed Action',
    USER_NOTIFICATION_REFRESH_TIMER = '[USER_NOTIFICATION] REFRESH TIMER Action',
}

export const loadUserNotifications = createAction(
    UserNotificationAction.USER_NOTIFICATION_LOAD,
);

export const loadedUserNotifications = createAction(
    UserNotificationAction.USER_NOTIFICATION_LOADED,
    props<{ data: UserNotification[] }>(),
);

export const readUserNotification = createAction(
    UserNotificationAction.USER_NOTIFICATION_READ,
    props<{ id: string }>(),
);

export const readDoneUserNotification = createAction(
    UserNotificationAction.USER_NOTIFICATION_READ_DONE,
    props<{ data: UserNotification }>(),
);

export const unreadUserNotification = createAction(
    UserNotificationAction.USER_NOTIFICATION_UNREAD,
    props<{ id: string }>(),
);

export const unreadDoneUserNotification = createAction(
    UserNotificationAction.USER_NOTIFICATION_UNREAD_DONE,
    props<{ data: UserNotification }>(),
);

export const readAllUserNotification = createAction(
    UserNotificationAction.USER_NOTIFICATION_READ_ALL,
    props<{ label: string }>(),
);

export const readAllDoneUserNotification = createAction(
    UserNotificationAction.USER_NOTIFICATION_READ_ALL_DONE,
    props<{ label: string }>(),
);

export const receivedUserNotification = createAction(
    UserNotificationAction.USER_NOTIFICATION_RECEIVED,
    props<{ data: Notification }>(),
);

export const receivedStopUserNotification = createAction(
    UserNotificationAction.USER_NOTIFICATION_STOP_RECEIVED,
    props<{ data: Notification }>(),
);

export const failedUserNotifications = createAction(
    UserNotificationAction.USER_NOTIFICATION_FAILED,
    props<{ error: any }>(),
);

export const refreshTimerUserNotifications = createAction(
    UserNotificationAction.USER_NOTIFICATION_REFRESH_TIMER,
);