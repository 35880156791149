import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {ApplicationStateService} from '../../services/application-state.service';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {User} from '../../entities/User';
import {environment} from '@/environments/environment';
import {ExternalPagesUtils} from '../../utilities/externalPagesUtils';
import {MixpanelService} from '@/app/services/mixpanel.service';

@Component({
    selector: 'app-shiny',
    templateUrl: './external-page.component.html',
    styleUrls: ['./external-page.component.scss'],
})
export class ExternalPageComponent implements OnInit, OnDestroy {
    invalidUrl;
    iframeUrl;

    @ViewChild('externalPage') externalPage: ElementRef;
    subscriptions = new Subscription();

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected sanitizer: DomSanitizer,
        protected applicationState: ApplicationStateService,
        protected http: HttpClient,
        private mixPanelService: MixpanelService) {
    }

    ngOnInit(): void {
        this.loadInInit();
    }

    loadInInit() {
        this.route.paramMap.subscribe(route => {
            const insightId = route.get('insightId');
            this.subscriptions.add(this.applicationState.getState('clientConfiguration').subscribe(async configuration => {
                const externalPages = [];
                this.addParameterToSisensePages(configuration.sisensePages);
                configuration.sisensePages.map(page => page.type = 'Sisense');
                configuration.shinyPages.map(page => page.type = 'Shiny');
                configuration.prototypePages.map(page => page.type = 'Streamlit');
                externalPages.push.apply(externalPages, configuration.shinyPages);
                externalPages.push.apply(externalPages, configuration.sisensePages);
                externalPages.push.apply(externalPages, configuration.prototypePages);
                externalPages.push.apply(externalPages, ExternalPagesUtils.getDefaultExternalPagesForAdmin());

                const page = externalPages.find(x => x.name === insightId);

                if (page) {
                    // Add Mix Panel page view tracking for all external pages
                    this.mixPanelService.track('Page view', {
                        client: configuration.client,
                        pageURL: page.url,
                        pageTitle: page.name,
                        pageType: page.type,
                    });
                    if (page.url.includes('streamlit')) {
                        const user: User = this.applicationState.getUser();
                        // Replace mappingFlowId dynamic from url
                        // For that cases when the mappingFlowId is variable
                        // example data overview "View / Approve Raw Data"
                        const mappingFlowId = route.get('mappingFlowId');
                        if (mappingFlowId && page.url.includes('<MAPPING_FLOW_ID>')) {
                            page.url = page.url.replace('<MAPPING_FLOW_ID>', mappingFlowId);
                        }
                        // for the link to model validation in Model Configuration
                        const modelVersion = route.get('modelVersion');
                        if (modelVersion && page.url.includes('<MODEL_VERSION>')) {
                            page.url = page.url.replace('<MODEL_VERSION>', modelVersion);
                        }
                        const queryParams: Params = {
                            customer: configuration.client,
                            userId: user._id,
                            backendAbbreviation: configuration.backendAbbreviation,
                            userName: `${user.firstName} ${user.lastName}`,
                        };
                        let urlWithoutParameters = null;
                        if ((page.url.match(/\?/g) || []).length === 2) { // parse queryParams if they presented in prototype url
                            const pageParameters = page.url.substring(page.url.lastIndexOf('?') + 1, page.url.length);
                            urlWithoutParameters = page.url.substring(0, page.url.lastIndexOf('?'));
                            const pageParametersArray = pageParameters.split('&');
                            for (const param of pageParametersArray) {
                                const paramName = param.split('=')[0];
                                if (paramName.toLocaleLowerCase() !== 'customer') {
                                    queryParams[paramName] = param.split('=')[1];
                                }
                            }
                        }
                        await this.router.navigate(
                            [],
                            {
                                relativeTo: this.route,
                                queryParams: queryParams,
                                queryParamsHandling: 'merge',
                                skipLocationChange: true
                            });
                        let url = (urlWithoutParameters == null ? page.url : urlWithoutParameters)
                            + '&userId=' + user._id;
                        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                        while (this.externalPage == null) {
                            await this.timer(500);
                        }
                        const content = this.externalPage.nativeElement.contentDocument?.document
                            || this.externalPage.nativeElement.contentWindow
                            || this.externalPage.nativeElement.contentWindow;
                        while (content == null) {
                            await this.timer(500);
                        }
                        url = url.replace('root', 'index');
                        this.http.get(url, {responseType: 'text'}).subscribe(async data => {
                            content.document.open();
                            content.document.write(data);
                            content.document.close();
                        });
                        return;
                    }
                    const filterId = this.route.snapshot.queryParamMap.get('filterId');
                    if (filterId != null) {
                        const filter = await this.http.get(environment.api_url + 'basics/short-link?filterId=' + filterId, {responseType: 'text'}).toPromise();
                        if (filter != null) {
                            page.url = page.url + '&filter=' + filter;
                        }
                    }
                    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(page.url);
                } else {
                    this.invalidUrl = true;
                }
            }));
        });
    }

    timer(ms) {
        return new Promise(res => setTimeout(res, ms));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private addParameterToSisensePages(sisensePages: any) {
        for (const page of sisensePages) {
            const currentLocation = this.router.url.substring(1);

            const existingParameterIndex = page.url.indexOf('&op_page=');
            const newOpParameter = '&op_page=' + currentLocation;

            if (existingParameterIndex === -1) {
                page.url = page.url.concat(newOpParameter);
            } else {
                page.url = page.url.substring(0, existingParameterIndex).concat(newOpParameter);
            }
        }
    }
}
