import {Inject, Injectable, Optional} from '@angular/core';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import dayjs, {Dayjs} from 'dayjs';

@Injectable()
export class CustomDateAdapter extends DateAdapter<Dayjs> {

    private localeData: {
        firstDayOfWeek: number,
        longMonths: string[],
        shortMonths: string[],
        dates: string[],
        longDaysOfWeek: string[],
        shortDaysOfWeek: string[],
        narrowDaysOfWeek: string[]
    };

    constructor(@Optional() @Inject(MAT_DATE_LOCALE) public dateLocale: string) {
        super();
        this.setLocale(dateLocale);
    }

    setLocale(locale: string) {
        super.setLocale(locale);
        const dayJsLocaleData = dayjs().localeData();
        this.localeData = {
            firstDayOfWeek: dayJsLocaleData.firstDayOfWeek(),
            longMonths: dayJsLocaleData.months(),
            shortMonths: dayJsLocaleData.monthsShort(),
            dates: this.range(31, (i) => this.createDate(2017, 0, i + 1).format('D')),
            longDaysOfWeek: this.range(7, (i) => dayjs().set('day', i).format('dddd')),
            shortDaysOfWeek: dayJsLocaleData.weekdaysShort(),
            narrowDaysOfWeek: dayJsLocaleData.weekdaysMin(),
        };
    }

    getFirstDayOfWeek(): number {
        return 1;
    }

    addCalendarYears(date: Dayjs, years: number): Dayjs {
        return date.add(years, 'year');
    }

    addCalendarMonths(date: Dayjs, months: number): Dayjs {
        return date.add(months, 'month');
    }

    addCalendarDays(date: Dayjs, days: number): Dayjs {
        return date.add(days, 'day');
    }

    clone(date: Dayjs): Dayjs {
        return date.clone();
    }

    createDate(year: number, month: number, date: number): Dayjs {
        return dayjs().set('year', year).set('month', month).set('date', date);
    }

    format(date: Dayjs, displayFormat: string): string {
        if (!this.isValid(date)) {
            throw Error('DayjsDateAdapter: Cannot format invalid date.');
        }
        return date.locale(this.locale).format(displayFormat);
    }


    getDateNames(): string[] {
        return this.localeData.dates;
    }

    getDayOfWeek(date: Dayjs): number {
        return dayjs(date).day();
    }

    getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
        if (style === 'long') {
            return this.localeData.longDaysOfWeek;
        }
        if (style === 'short') {
            return this.localeData.shortDaysOfWeek;
        }
        return this.localeData.narrowDaysOfWeek;
    }

    getYear(date: Dayjs): number {
        return dayjs(date).year();
    }

    getMonth(date: Dayjs): number {
        return dayjs(date).month();
    }

    getDate(date: Dayjs): number {
        return dayjs(date).date();
    }

    getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
        return style === 'long' ? this.localeData.longMonths : this.localeData.shortMonths;
    }

    getNumDaysInMonth(date: Dayjs): number {
        return dayjs(date).daysInMonth();
    }

    getYearName(date: Dayjs): string {
        return dayjs(date).format('YYYY');
    }

    invalid(): Dayjs {
        return dayjs(null);
    }

    isDateInstance(obj: any): boolean {
        return dayjs.isDayjs(obj);
    }

    isValid(date: Dayjs): boolean {
        return dayjs(date).isValid();
    }

    parse(value: any, parseFormat: string): Dayjs | null {
        if (value && typeof value === 'string') {
            return dayjs(value, parseFormat);
        }
        return value ? dayjs(value) : null;
    }

    toIso8601(date: Dayjs): string {
        return date.toISOString();
    }

    today(): Dayjs {
        return dayjs();
    }

    /** Creates an array and fills it with values. */
    range<T>(length: number, valueFunction: (index: number) => T): T[] {
        const valuesArray = Array(length);
        for (let i = 0; i < length; i++) {
            valuesArray[i] = valueFunction(i);
        }
        return valuesArray;
    }
}
