import {Pipe, PipeTransform} from '@angular/core';

/*
 * Sort Array:
 * by 'asc' or 'desc', which stands for ascending or 'descending' order
 * Usage:
 *   Array | alphabeticalOrder: order: <string>
 * Example:
 *   {{ <Array> | alphabeticalOrder: order: 'desc' }}
 *   sorts array alphabetically in descending order
 */

@Pipe({name: 'alphabeticalOrder'})
export class AlphabeticalOrderPipe implements PipeTransform {
    transform(value: any[], order?: string): any[] {
        if (!value || value.length <= 1) {
            return value;
        }
        switch (order) {
            case 'desc':
                return value.sort().reverse();
            default:
                return value.sort();
        }
    }
}
