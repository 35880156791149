import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ApplicationStateService} from './application-state.service';
import {map} from 'rxjs/operators';

export const AdminGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
):
    Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    return inject(ApplicationStateService).getState('permissions').pipe(
        map(userGroup => {
            if (userGroup.accessLevel === 'ADMIN' || userGroup.accessLevel === 'SUPER_ADMIN') {
                return true;
            } else {
                inject(Router).navigate(['/dashboard/unauthorized']);
                return false;
            }
        }),
    );
};
