export class OppieEvent {
    eventType: EventType;
    origin: EventOrigin;
    messageToUser: string;
    actionText: string;
    dismissText: string;

    constructor(eventType: EventType, origin?: EventOrigin) {
        this.eventType = eventType;
        this.origin = origin;
    }
}

export enum EventType {
    CAMPAIGN_OPTIMIZATION,
    NEW_CAMPAIGN_OPTIMIZATION,
    OLD_CAMPAIGN_OPTIMIZATION,
    OLD_CAMPAIGN_OPTIMIZATION_WITH_CHANGES,
    CAMPAIGN_OPTIMIZATION_ERROR,
    CAMPAIGN_OPTIMIZATION_FAILED,
    CAMPAIGN_RESET_CLOSE,
    CAMPAIGN_WITH_PREVIOUS_CROSS_CAMPAIGN_OPTIMIZATION,
    DISCARD_RUNNING_OPTIMIZATION,
    CROSS_CAMPAIGN_OPTIMIZATION,
    CROSS_CAMPAIGN_OPTIMIZATION_FAILED,
    OLD_CROSS_CAMPAIGN_OPTIMIZATION,
    OPPIE_DISABLED,
    OPPIE_ENABLED,
    OPPIE_LOADING
}

export enum EventOrigin {
    OPPIE,
    CAMPAIGN_EDITOR,
    CAMPAIGN_OVERVIEW,
}
