import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from '@/environments/environment';
import {
    archivedScheduledNotification,
    deletedScheduledNotifications,
    failedScheduledNotifications,
    loadedScheduledNotifications,
    loadScheduledNotifications,
    savedScheduledNotification,
    ScheduledNotificationAction,
} from './scheduled-notification.action';
import {Notification} from '../notification.interface';
import {NotificationService} from '@/app/services/notification.service';

@Injectable()
export class ScheduledNotificationEffects {

    loadScheduleNotification$ = createEffect(() => this.actions$.pipe(
        ofType(ScheduledNotificationAction.SCHEDULED_NOTIFICATION_LOAD),
        mergeMap(() => {
                return this.http.get<Notification[]>(environment.api_url + 'notifications/scheduled').pipe(
                    map(data => {
                        return loadedScheduledNotifications({data});
                    }),
                    catchError((error) => of(failedScheduledNotifications({error}))),
                );
            },
        ),
    ));

    saveScheduleNotification$ = createEffect(() => this.actions$.pipe(
        ofType(ScheduledNotificationAction.SCHEDULED_NOTIFICATION_SAVE),
        mergeMap((data: any) => {
                const notification: Notification = data.data;
                let method = 'POST';
                let url = 'notifications';
                if (notification.id) {
                    method = 'PUT';
                    url = 'notifications/' + notification.id;
                }
                return this.http.request<Notification>(method, environment.api_url + url, {body: notification}).pipe(
                    map(result => {
                        this.notificationService.success('Notification saved successfully.', 'Success!');
                        return savedScheduledNotification({result});
                    }),
                    catchError((error) => {
                        this.notificationService.error(error.error.message, 'Error!');
                        return of(loadScheduledNotifications());
                    }),
                );
            },
        ),
    ));

    deleteScheduleNotification$ = createEffect(() => this.actions$.pipe(
        ofType(ScheduledNotificationAction.SCHEDULED_NOTIFICATION_DELETE),
        mergeMap((data: any) => {
                const ids: string[] = data.ids;

                return this.http.delete<void>(environment.api_url + 'notifications', {body: ids}).pipe(
                    map(result => {
                        this.notificationService.success('Notifications deleted successfully.', 'Success!');
                        return deletedScheduledNotifications();
                    }),
                    catchError((error) => of(failedScheduledNotifications({error}))),
                );
            },
        ),
    ));

    reloadNotification$ = createEffect(() => this.actions$.pipe(
        ofType(ScheduledNotificationAction.SCHEDULED_NOTIFICATION_SAVED, ScheduledNotificationAction.SCHEDULED_NOTIFICATION_DELETED),
        mergeMap(() => of(loadScheduledNotifications())),
    ));

    archiveScheduleNotification$ = createEffect(() => this.actions$.pipe(
        ofType(ScheduledNotificationAction.SCHEDULED_NOTIFICATION_ARCHIVE),
        mergeMap((payload: any) => {
                return this.http.patch<Notification>(environment.api_url + 'notifications/' + payload.id + '/archive', null).pipe(
                    map((data: Notification) => {
                        return archivedScheduledNotification({data});
                    }),
                    catchError((error) => of(failedScheduledNotifications({error}))),
                );
            },
        ),
    ));

    constructor(private actions$: Actions, private http: HttpClient, private notificationService: NotificationService) {
    }
}
