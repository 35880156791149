import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {KpiMetric} from '@/app/entities/KpiMetric';
import {TranslationService} from '@/app/services/translation.service';
import {NumberUtils} from '@/app/utilities/shared/NumberUtils';
import {KeyPerformanceIndicator} from '@/app/entities/keyPerformanceIndicator';

/*
 * Format the number belonging to a kpi:
 * styling the number itself, but also adding a monetary symbol if needed
 * the monetary symbol is also translated
 * optionally you can send monetary true or false if you don't know the KPI at that place
 * Usage:
 *   value | styleKpi:keyPerformanceIndicator
 * Example:
 *   {{ 535110000,353 | styleKpi:Revenue }}
 *   formats to: €535,110,000.35
*/
@Injectable({
    providedIn: 'root',
})
@Pipe({name: 'styleKpi'})
export class StyleKpiPipe implements PipeTransform {

    transform(value: string | number, kpi: KpiMetric | KeyPerformanceIndicator): string {
        // make sure we only transform numbers
        let numericValue: number = typeof value === 'string' ? parseFloat(value) : value;

        if (isNaN(numericValue)) {
            return value + '';
        }

        if (kpi.percentage) {
            numericValue = numericValue / 100;
        }
        const decimals = kpi && (kpi as KeyPerformanceIndicator)?.calculationDenominator ? 2 : 0;
        const monetary = !!((kpi as KeyPerformanceIndicator)?.monetary || (kpi as KpiMetric)?.isMonetary);
        ;
        const currencySymbol = monetary ? TranslationService.translate('monetarySymbol') : '';
        const formattedNumber = NumberUtils.formatNumber(numericValue, monetary, decimals);
        if (kpi.percentage) {
            return formattedNumber + '%';
        } else {
            return currencySymbol + formattedNumber;
        }
    }
}
