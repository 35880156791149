<div class="auth">
    <div class="auth__form">
        <div class="auth__form__wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="auth__visual">
        <h1><span>Take control</span> of your media investments</h1>
        <img alt="visual" src="/assets/img/Marketing-Strategy.png"/>
    </div>
</div>