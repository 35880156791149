import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ApplicationStateService} from '../../services/application-state.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-client-selection',
    templateUrl: './client-selection.component.html',
    styleUrls: ['./client-selection.component.scss']
})
export class ClientSelectionComponent implements OnInit, OnDestroy {
    subscription = new Subscription();
    selectedClient;
    clients = [];
    clientLogo = '';
    userClientConf;
    isMenuHidden = true;

    @ViewChild('clientSelector') clientSelector: ElementRef;
    @ViewChild('clientDropdown') clientDropdown: ElementRef;

    constructor(private stateObject: ApplicationStateService,
                private http: HttpClient,
                private router: Router,
                private renderer: Renderer2) {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (e.target !== this.clientDropdown?.nativeElement &&
                e.target !== this.clientSelector?.nativeElement &&
                !this.clientSelector?.nativeElement.contains(e.target)) {
                this.isMenuHidden = true;
            }
        });
    }

    ngOnInit() {

        this.subscription.add(this.stateObject.getState('userGroups').subscribe(userGroups =>
            this.clients = userGroups.map(({userGroup: {client}}) => client)
        ));
        this.subscription.add(this.stateObject.getState('clientConfiguration').subscribe((clientConfiguration) => {
            this.selectedClient = clientConfiguration.client;
            this.setClientLogo(clientConfiguration.logo);
        }));
        this.subscription.add(this.stateObject.getState('userClientConfig').subscribe((conf: any) => {
            this.userClientConf = conf;
        }));
    }

    setClientLogo(logo) {
        if (logo && logo.length) {
            this.clientLogo = environment.base_url + 'logos/' + this.selectedClient + '/' + logo;
        }
    }

    changeClient(selectedClient) {
        if (this.selectedClient !== selectedClient) {
            this.selectedClient = selectedClient;
            this.stateObject.resetState();
            localStorage.setItem('selected_client', this.selectedClient);
            this.stateObject.setInitialState(this.userClientConf, this.selectedClient);
            const clientConfiguration = this.userClientConf.clientConfigurationList.find(({client}) =>
                client === this.selectedClient
            );
            this.setClientLogo(clientConfiguration.logo);
            this.router.navigateByUrl('');
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    toggleMenu() {
        if (this.clients.length > 1) {
            this.isMenuHidden = !this.isMenuHidden;
        }

    }
}

