import {createReducer, on} from '@ngrx/store';
import {ScheduledNotificationState} from '../notification.interface';
import {
    archivedScheduledNotification,
    archiveScheduledNotification,
    deleteScheduledNotifications,
    editScheduledNotification,
    failedScheduledNotifications,
    loadedScheduledNotifications,
    loadScheduledNotifications,
    reloadScheduledNotification,
    savedScheduledNotification,
    saveScheduledNotification,
} from './scheduled-notification.action';
import dayjs from 'dayjs';
import _ from 'lodash-es';

export const initialState: ScheduledNotificationState = {
    future: [],
    past: [],
    loading: false,
    saving: false,
    beingEdited: null,
    reload: false,
};

export const scheduledNotificationReducer = createReducer(
    initialState,
    on(loadScheduledNotifications, deleteScheduledNotifications, archiveScheduledNotification,
        (state) => ({...state, beingEdited: null, loading: true, reload: false})),
    on(loadedScheduledNotifications, (state, data) => ({
        ...state,
        future: data.data.filter(n => dayjs(n.scheduledDate).isAfter(dayjs())),
        past: data.data.filter(n => dayjs(n.scheduledDate).isBefore(dayjs())),
        loading: false,
        saving: false,
        beingEdited: null,
        reload: false,
    })),
    on(archivedScheduledNotification, (state, data) => {
        const past = _.cloneDeep(state.past);
        past.forEach(n => {
            if (n.id === data.data.id) {
                n.status = data.data.status;
            }
        });
        return ({...state, past, loading: false, reload: false});
    }),
    on(editScheduledNotification, (state, data) => ({...state, beingEdited: data.notification, reload: false})),
    on(saveScheduledNotification, (state) => ({...state, saving: true})),
    on(savedScheduledNotification, (state, data) => ({...state, beingEdited: null, saving: false, reload: false})),
    on(reloadScheduledNotification, (state) => ({...state, beingEdited: null, saving: false, reload: true})),
    on(failedScheduledNotifications, (state) => ({...state, loading: false, saving: false, beingEdited: null, reload: false})),
);
