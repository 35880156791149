import {EventOrigin, EventType, OppieEvent} from '../entities/OppieEvent';

export class EventFactory {
    private static readonly campaignOptErrorMsg = 'Hi, I can help you with optimizing your campaign but I need some more details. Please provide: ';
    private static readonly YES = 'Yes, please';
    private static readonly YES_DISCARD = 'Yes, discard';
    private static readonly NO = 'No, thanks';
    private static readonly IM_OPPIE = '<div class="mb-2">Hey, I’m Oppie!</div>';

    public static createEvent(eventType: EventType, eventOrigin?: EventOrigin, customMessage?: string): OppieEvent {
        switch (eventType) {
            case EventType.OPPIE_LOADING:
                return this.createLoadingEvent(eventOrigin);
            case EventType.CAMPAIGN_RESET_CLOSE:
                return this.createCloseResetEvent(eventOrigin);
            case EventType.CAMPAIGN_OPTIMIZATION:
                return this.createCampaignOptimizationEvent(eventOrigin);
            case EventType.NEW_CAMPAIGN_OPTIMIZATION:
                return this.createOptimizationEventForNewCampaign(eventOrigin);
            case EventType.OLD_CAMPAIGN_OPTIMIZATION:
                return this.createOldCampaignOptimizationEvent(eventOrigin);
            case EventType.OLD_CAMPAIGN_OPTIMIZATION_WITH_CHANGES:
                return this.createOldCampaignWithChangesOptimizationEvent(eventOrigin);
            case EventType.CAMPAIGN_WITH_PREVIOUS_CROSS_CAMPAIGN_OPTIMIZATION:
                return this.createCampaignOptimizationWithPreviousCrossCampaignEvent(eventOrigin);
            case EventType.CAMPAIGN_OPTIMIZATION_ERROR:
                return this.createCampaignOptimizationErrorEvent(eventOrigin, customMessage);
            case EventType.CAMPAIGN_OPTIMIZATION_FAILED:
                return this.createCampaignOptimizationFailedEvent(eventOrigin);
            case EventType.DISCARD_RUNNING_OPTIMIZATION:
                return this.createDiscardOptimizationOppieEvent(eventOrigin);
            case EventType.CROSS_CAMPAIGN_OPTIMIZATION:
                return this.createCrossCampaignOptimizationEvent(eventOrigin);
            case EventType.OLD_CROSS_CAMPAIGN_OPTIMIZATION:
                return this.createOldCrossCampaignOptimizationEvent(eventOrigin);
            case EventType.CROSS_CAMPAIGN_OPTIMIZATION_FAILED:
                return this.createCrossCampaignOptimizationFailedEvent(eventOrigin);
            case EventType.OPPIE_DISABLED:
                return this.createDisableOppieEvent();
            case EventType.OPPIE_ENABLED:
                return this.createEnableOppieEvent();
            default:
                throw new Error('Invalid Event sent!');
        }
    }

    public static createResponseEvent(originalEvent: OppieEvent): OppieEvent {
        originalEvent.origin = EventOrigin.OPPIE;
        return originalEvent;
    }

    static createCampaignOptErrorMsg(campaignErrors: string[]) {
        return this.campaignOptErrorMsg + campaignErrors.join(', ');
    }

    private static createCampaignOptimizationEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.CAMPAIGN_OPTIMIZATION, eventOrigin);
        event.actionText = EventFactory.YES;
        event.dismissText = EventFactory.NO;
        event.messageToUser = EventFactory.IM_OPPIE + 'Would you like some help with optimising your campaign?';
        return event;
    }

    private static createCampaignOptimizationWithPreviousCrossCampaignEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.CAMPAIGN_WITH_PREVIOUS_CROSS_CAMPAIGN_OPTIMIZATION, eventOrigin);
        event.actionText = EventFactory.YES_DISCARD;
        event.dismissText = EventFactory.NO;
        event.messageToUser = EventFactory.IM_OPPIE +
            'Seems like you already have a <b>cross-campaign optimization</b> process for this campaign. Would you like to <b>discard</b> it?';
        return event;
    }

    private static createCloseResetEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.CAMPAIGN_RESET_CLOSE, eventOrigin);
        event.actionText = EventFactory.YES;
        event.dismissText = EventFactory.NO;
        event.messageToUser = EventFactory.IM_OPPIE + 'Would you like some help with optimizing your campaign?';
        return event;
    }

    private static createLoadingEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.OPPIE_LOADING, eventOrigin);
        event.actionText = null;
        event.dismissText = null;
        event.messageToUser = 'Please hold a few seconds while I analyze your data...';
        return event;
    }

    private static createOptimizationEventForNewCampaign(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.NEW_CAMPAIGN_OPTIMIZATION, eventOrigin);
        event.actionText = 'Save campaign';
        event.dismissText = EventFactory.NO;
        event.messageToUser = EventFactory.IM_OPPIE + 'Please save a first copy of this campaign so that I\'m able to optimize for you!';
        return event;
    }

    private static createCampaignOptimizationErrorEvent(eventOrigin: EventOrigin, customMessage: string) {
        const event = new OppieEvent(EventType.CAMPAIGN_OPTIMIZATION_ERROR, eventOrigin);
        event.dismissText = 'Dismiss Message';
        event.messageToUser = customMessage;
        return event;
    }

    private static createCampaignOptimizationFailedEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.CAMPAIGN_OPTIMIZATION_FAILED, eventOrigin);
        event.actionText = EventFactory.YES;
        event.dismissText = EventFactory.NO;
        event.messageToUser = 'The last optimization failed. Would you like to try again?';
        return event;
    }

    private static createOldCampaignOptimizationEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.OLD_CAMPAIGN_OPTIMIZATION, eventOrigin);
        event.actionText = EventFactory.YES;
        event.dismissText = EventFactory.NO;
        event.messageToUser = EventFactory.IM_OPPIE + 'Your campaign optimization is <b>READY</b> to be applied. Would you like to take a look?</div>';
        return event;
    }

    private static createOldCampaignWithChangesOptimizationEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.OLD_CAMPAIGN_OPTIMIZATION_WITH_CHANGES, eventOrigin);
        event.actionText = 'Show Previous Optimization';
        event.dismissText = 'Dismiss Message';
        event.messageToUser = EventFactory.IM_OPPIE +
            'I see you made changes to your campaign, please be aware that the optimized result will be discarded once you save this changes!';
        return event;
    }

    private static createDisableOppieEvent() {
        return new OppieEvent(EventType.OPPIE_DISABLED);
    }

    private static createEnableOppieEvent() {
        return new OppieEvent(EventType.OPPIE_ENABLED);
    }

    private static createDiscardOptimizationOppieEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.DISCARD_RUNNING_OPTIMIZATION, eventOrigin);
        event.actionText = EventFactory.YES_DISCARD;
        event.dismissText = EventFactory.NO;
        event.messageToUser = EventFactory.IM_OPPIE +
            'Your optimization is <b>RUNNING</b>. Do you want to stop and discard the current optimization?';
        return event;
    }

    private static createCrossCampaignOptimizationEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.CROSS_CAMPAIGN_OPTIMIZATION, eventOrigin);
        event.actionText = EventFactory.YES;
        event.dismissText = EventFactory.NO;
        event.messageToUser = EventFactory.IM_OPPIE + 'Would you like some help with campaign optimizing?';
        return event;
    }

    private static createOldCrossCampaignOptimizationEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.OLD_CROSS_CAMPAIGN_OPTIMIZATION, eventOrigin);
        event.actionText = EventFactory.YES;
        event.dismissText = EventFactory.NO;
        event.messageToUser = EventFactory.IM_OPPIE + 'Your cross-campaign optimization is <b>READY</b> to be applied. Would you like to take a look?</div>';
        return event;
    }

    private static createCrossCampaignOptimizationFailedEvent(eventOrigin: EventOrigin) {
        const event = new OppieEvent(EventType.CROSS_CAMPAIGN_OPTIMIZATION_FAILED, eventOrigin);
        event.actionText = EventFactory.YES;
        event.dismissText = EventFactory.NO;
        event.messageToUser = 'The last cross-optimization failed. Would you like to try again?';
        return event;
    }

}
