import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'idFormatter'
})
export class IdFormatterPipe implements PipeTransform {

    transform(value: string, args?: any): string {
        return value.replace(/ /g, '_');
    }

}
