import {Injectable} from '@angular/core';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import dayjs, {Dayjs} from 'dayjs';
import {DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_REFER_FORMAT, ISO_DATE_FORMAT, ISO_DATE_TIME_FORMAT} from '@/app/utilities/shared/DateTimeConstants';

@Injectable({
    providedIn: 'root',
})
export class DateformatService {

    /**
     * Transforms ngbDate object to a string formatted as DD/MM/YYYY
     * @param date
     * @Deprecated
     */
    public ngbDateToDisplay(date: NgbDate) {
        const year = date.year;
        const month = date.month;
        const day = date.day;
        let monthText;
        let dayText;

        if (month < 10) {
            monthText = '0' + month;
        } else {
            monthText = month;
        }

        if (day < 10) {
            dayText = '0' + day;
        } else {
            dayText = day;
        }

        return dayText + '/' + monthText + '/' + year;
    }

    /**
     * Tranforms a string (YYYY-mm-dd) to an ngbdate
     * @param dateString
     */
    public ngbDateFromString(dateString: String) {
        const dateParts = dateString.split('-');
        return new NgbDate(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10), parseInt(dateParts[2], 10));
    }

    /**
     * Tranforms a string (DD/MM/YYYY) to an ngbdate
     * @param dateString
     */
    public ngbDateFromStringddmmYYYY(dateString: String) {
        const dateParts = dateString.split('/');
        return new NgbDate(parseInt(dateParts[2], 10), parseInt(dateParts[1], 10), parseInt(dateParts[0], 10));
    }

    public isNgbDate(date) {
        if (Object.keys(date).length === 3) {
            return date.year && date.month && date.day;
        } else {
            return false;
        }
    }

    /**
     * Get difference between two dates in days
     */
    public getDateDiff(date1, date2) {
        // Let's ensure consistency between the two dates and use dayjs.js library to
        // execute any calculations needed and ensure as much accuracy as possible
        date1 = this.getDayjsFrom(date1);
        date2 = this.getDayjsFrom(date2);
        return Math.abs(date1.diff(date2, 'day'));
    }

    // get dates formatted YYYY-MM-DD between given dates, for WEEKLY granularity get only mondays
    public getDatesBetween(startDate: Dayjs, endDate: Dayjs, granularity: string) {
        const dateArray = [];
        let currentDate = startDate;
        while (currentDate.isSameOrBefore(endDate)) {
            if (granularity === 'WEEKLY') {
                if (currentDate.isoWeekday() === 1) {
                    dateArray.push(this.getDateISOFormatted(currentDate));
                }
            } else {
                dateArray.push(this.getDateISOFormatted(currentDate));
            }
            currentDate = currentDate.add(1, 'day');
        }
        return dateArray;
    }

    public startOfCurrentYear() {
        return dayjs().startOf('year');
    }

    public endOfCurrentYear() {
        return dayjs().endOf('year');
    }

    public isSame(date1: any, date2: any) {
        return this.getDayjsFrom(date1).isSame(this.getDayjsFrom(date2), 'day');
    }

    public isSameOrBefore(date1: any, date2: any) {
        date1 = this.getDayjsFrom(date1);
        date2 = this.getDayjsFrom(date2);
        return date1.isSameOrBefore(date2, 'day');
    }

    public isSameOrAfter(date1: any, date2: any) {
        date1 = this.getDayjsFrom(date1);
        date2 = this.getDayjsFrom(date2);
        return date1.isSameOrAfter(date2, 'day');
    }

    /**
     * Method which can handle any date in any format and cast it to Dayjs
     * @param date
     */
    public getDayjsFrom(date: any): Dayjs {
        if (date == null) {
            return dayjs('Invalid');
        }
        // TODO ngbdate should be removed when we will switch on or datepicker component everywhere
        if (date instanceof NgbDate || this.isNgbDate(date)) {
            return dayjs(new Date(date.year, date.month - 1, date.day));
        }
        if (typeof date === 'string' || date instanceof String) {
            let dateTimeFormat = ISO_DATE_TIME_FORMAT;
            let dateFormat = ISO_DATE_FORMAT;
            if (date.includes('/')) {
                dateTimeFormat = DATE_TIME_FORMAT;
                dateFormat = DATE_FORMAT;
            }
            if (date.includes(':')) {
                return dayjs('' + date, dateTimeFormat);
            } else {
                return dayjs('' + date, dateFormat);
            }
        } else {
            const dayjsDate: Dayjs = dayjs(date);
            if (dayjsDate.isValid()) {
                return dayjsDate;
            }
        }
        return date;
    }

    /**
     * Format any date type to specific format string
     * @param date
     * @param format
     */
    public formatDate(date: any, format: string): string {
        return this.getDayjsFrom(date).format(format);
    }

    /**
     * Format any date type to DD/MM/YYYY string
     * @param date
     */
    public getDateFormatted(date: any): string {
        return this.getDayjsFrom(date).format(DATE_FORMAT);
    }

    /**
     * Format any date type to DD/MM/YYYY HH:mm:ss string
     * @param date
     */
    public getDateTimeFormatted(date: any) {
        return this.getDayjsFrom(date).format(DATE_TIME_FORMAT);
    }

    /**
     * Format any date type to DD/MM/YYYY HH:mm[AM/PM] string
     * @param date
     */
    public getDateTimeReferFormatted(date: any) {
        return this.getDayjsFrom(date).format(DATE_TIME_REFER_FORMAT);
    }

    /**
     * Format from DD/MM/YYYY HH:mm[AM/PM] to dayjs
     * @param date
     */
    public getDayjsFromTimeReferFormatted(date: string) {
        return dayjs(date, DATE_TIME_REFER_FORMAT);
    }

    /**
     * Format any date type to YYYY-MM-DD sting
     */
    public getDateISOFormatted(date: any) {
        return this.getDayjsFrom(date).format(ISO_DATE_FORMAT);
    }

    /**
     * Get dayjs formatted as YYYY-MM-DD HH:mm:ss
     */
    public getDateTimeISOFormatted(date: any) {
        return this.getDayjsFrom(date).format(ISO_DATE_TIME_FORMAT);
    }

    /**
     * True for any valid date type
     */
    public isValidDate(d) {
        return this.getDayjsFrom(d).isValid();
    }

    // if granularity WEEKLY make start date = monday of the week
    getStartDateAsWeekly(startDate, granularity) {
        if (granularity === 'WEEKLY') {
            startDate = this.getDayjsFrom(startDate);
            startDate = this.getDateISOFormatted(startDate.startOf('isoWeek'));
        }
        return startDate;
    }

    compareTwoFormattedWeekDates(dateA: string, dateB: string) {
        const indexOfFirstSeparator = dateA.indexOf('|');
        const indexOfSecondSeparator = dateB.indexOf('|');
        // Extract the year and compare, and afterward check the week number
        const firstYear = Number(dateA.substring(indexOfFirstSeparator + 3));
        const secondYear = Number(dateB.substring(indexOfSecondSeparator + 3));
        if (firstYear === secondYear) {
            const firstWeek = Number(dateA.substring(indexOfFirstSeparator - 3, indexOfFirstSeparator));
            const secondWeek = Number(dateB.substring(indexOfFirstSeparator - 3, indexOfFirstSeparator));
            return firstWeek < secondWeek ? -1 : 1;
        } else {
            return firstYear < secondYear ? -1 : 1;
        }
    }

    compareTwoFormattedQuarterDates(dateA: string, dateB: string) {
        const indexOfFirstSeparator = dateA.indexOf(' ');
        const indexOfSecondSeparator = dateB.indexOf(' ');
        // Extract the year and compare, and afterward check the quarter number
        const firstYear = Number(dateA.substring(indexOfFirstSeparator + 1));
        const secondYear = Number(dateB.substring(indexOfSecondSeparator + 1));
        if (firstYear === secondYear) {
            const firstQuarter = Number(dateA.substring(indexOfFirstSeparator - 1, indexOfFirstSeparator));
            const secondQuarter = Number(dateB.substring(indexOfSecondSeparator - 1, indexOfSecondSeparator));
            return firstQuarter < secondQuarter ? -1 : 1;
        } else {
            return firstYear < secondYear ? -1 : 1;
        }
    }

}
