<ng-template #content>
    <div class="modal-body">
        <div class="row p-4 text-start">
            <div class="col-12 mb-3">
                <label class="form-label" for="typeSelector">Issue Type</label>
                <div ngbDropdown>
                    <button class="form-control text-start" id="typeSelector" ngbDropdownToggle
                            style="min-width: 350px">{{ supportMailInfo.type }}
                    </button>
                    <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
                        <button (click)="selectedSupportMailType(option)" *ngFor="let option of options"
                                class="btn dropdown-item" ngbDropdownItem>{{ option }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-3">
                <label class="form-label" for="subject">Subject</label>
                <input [(ngModel)]="supportMailInfo.subject" class="form-control" id="subject" name="subject">
            </div>
            <div class="col-12 mb-3">
                <label class="form-label" for="message">Message</label>
                <textarea [(ngModel)]="supportMailInfo.message" class="form-control" id="message" name="message"
                          rows="5"></textarea>
            </div>
        </div>
        <div class="justify-content-between p-4">
            <button (click)="sendSupportMail()" [disabled]="sendingMail"
                    [ngClass]="{'btn-primary': !sendingMail, 'btn-secondary': sendingMail}" class="btn float-start">
                Send
            </button>
            <button (click)="dismiss()" [disabled]="sendingMail" class="btn btn-secondary float-end">Cancel</button>
        </div>
    </div>
</ng-template>

<div class="sidebar__extra">
    <a (click)="openSupportModal(content)" id="support-mail">
        <mat-icon>contact_support</mat-icon>
        <span class="menu-item__title">Contact OP support</span>
    </a>
</div>

<div class="sidebar__extra">
    <a [routerLink]="['/dashboard/notifications']" id="mail-box">
        <mat-icon>mail</mat-icon>
        <span class="menu-item__title">My Notifications</span>
    </a>
</div>

<div *ngIf="showNotificationAdmin" class="sidebar__extra">
    <a [routerLink]="['/dashboard/notifications/admin']" id="mail-admin">
        <mat-icon>timer</mat-icon>
        <span class="menu-item__title">Notification Admin</span>
    </a>
</div>

<div class="sidebar__extra">
    <a (click)="logout()" id="signOut">
        <mat-icon>logout</mat-icon>
        <span class="menu-item__title">logout</span>
    </a>
</div>
