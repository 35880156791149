import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {User} from '../entities/User';
import {NotificationService} from './notification.service';
import {ApplicationStateService} from './application-state.service';
import {LoginData} from '../entities/LoginData';
import {ApplicationInitService} from './application-init.service';
import {MixpanelService} from './mixpanel.service';
import {UserClientConfig} from '@/app/entities/UserClientConfig';
import {Store} from '@ngrx/store';
import * as froomRoot from '@/app/reducers';
import {loadUserNotifications} from '@/app/reducers/notification/user-notification/user-notification.action';
import {NotificationSseService} from '@/app/services/notification-sse.service';

@Injectable({
    providedIn: 'root',
})
export class InsightsIdentityService {
    clientId = environment.cognito_client_id;
    private appComponentSource = new Subject();

    appComponent$ = this.appComponentSource.asObservable();

    constructor(
        protected router: Router,
        protected http: HttpClient,
        protected applicationState: ApplicationStateService,
        protected notificationService: NotificationService,
        protected applicationInitService: ApplicationInitService,
        protected mixpanelService: MixpanelService,
        private notificationSseService: NotificationSseService,
        private store: Store<froomRoot.State>) {
    }

    /**
     * FixEmail returns the email in all lowercase
     * @param email
     */
    fixEmail(email) {
        return email.toLowerCase();
    }

    /**
     * Update password updates the users password
     * @param email
     * @param verificationCode
     * @param password
     * @param callback
     */
    updatePassword(email, verificationCode, password, callback) {
        email = this.fixEmail(email);

        const username = email;
    }

    /**
     * Login the user, callbacks are used in the case of success (service) and failure (component)
     * @param loginData
     * @param componentCallback
     */
    login(loginData: LoginData, componentCallback) {
        loginData.email = this.fixEmail(loginData.email);
        const body = {
            login: loginData.email,
            password: loginData.pw,
            verificationCode: loginData.verificationCode,
            rememberDevice: loginData.rememberDevice,
        };
        let headers_object = new HttpHeaders();
        headers_object = headers_object.append('Content-Type', 'application/json');

        const httpOptions = {
            headers: headers_object,
        };
        const bodyAsString = JSON.stringify(body);
        this.http.post(environment.api_url + 'login', bodyAsString, httpOptions).subscribe(
            data => {
                this.http.get(environment.api_url + 'user/myself', {}).toPromise().then(
                    (user: User) => {
                        this.setLoggedIn(user);
                        this.handleLogin(componentCallback);
                        this.applicationState.startRenewSession();
                        this.mixpanelService.initUser(user);
                        // Load user notifications
                        setTimeout(() => {
                            this.store.dispatch(loadUserNotifications());
                            this.notificationSseService.subscribeUserNotifications();
                        }, 1000);
                    },
                ).catch(error => {
                    this.setLoggedIn(null);
                });
            },
            error => {
                let response: HttpErrorResponse;
                response = error;
                componentCallback.handleLoginFailure(response.error.message);
            },
        );
    }

    handleLogin(componentCallback?: any) {
        this.applicationInitService.init().then(() => {
            this.http.get(environment.api_url + 'basics/userclientconfig').subscribe((data: UserClientConfig) => {
                this.applicationState.setInitialState(data);
                if (componentCallback && componentCallback.returnUrl) {
                    this.router.navigateByUrl(componentCallback.returnUrl);
                } else {
                    this.router.navigateByUrl('/dashboard').then(routeData => {
                    }).catch(err => {
                        console.log(err);
                    });
                }
            }, error => console.log(error));
        });
    }

    /**
     * Logout the user by removing all localstorage data
     */
    logout() {
        this.http.post(environment.api_url + 'logout', {}).subscribe(
            data => {
                this.applicationState.stopRenewSession();
                this.notificationSseService.unsubscribeUserNotifications();
                sessionStorage.clear();
                this.setLoggedIn(null);
                this.router.navigateByUrl('/auth/login');
            },
            error => {
                this.notificationService.error('Can\'t logout', error);
            },
        );
    }

    /**
     * RequestPasswordReset sends a request to the api so the user can reset their password
     * @param email
     * @param callback
     */
    requestPasswordReset(email, callback) {
        let headers_object = new HttpHeaders();

        headers_object = headers_object.append('Content-Type', 'application/x-www-form-urlencoded');

        const httpOptions = {
            headers: headers_object,
        };

        email = this.fixEmail(email);

        let params = new HttpParams();
        params = params.set('username', email);

        this.http.post(environment.api_url + 'user/resetPasswordRequest', params, httpOptions).subscribe(data => {
                callback.handleResetRequestSuccess(data);
            },
            error => {
                callback.handleResetRequestFailure(error);
            });
    }

    loggedIn() {
        return localStorage.getItem('isLoggedIn') === 'true';
    }

    impersonate(user: any) {
        let params = new HttpParams();
        params = params.set('username', user.username);
        params = params.set('client', this.applicationState.getClient());
        this.http.get(environment.api_url + 'login/impersonate', {params: params}).subscribe(
            data => {
                localStorage.setItem('impersonated_user', user.username);
                localStorage.setItem('impersonated_fullName',
                    user.middleName != null
                        ? user.firstName + ' ' + user.middleName + ' ' + user.lastName
                        : user.firstName + ' ' + user.lastName);
                this.handleLogin();
            },
            error => {
                this.notificationService.error(error.error.message, 'Can\'t switch user');
            });
    }

    switchUserBack() {
        let params = new HttpParams();
        params = params.set('client', this.applicationState.getClient());
        this.http.get(environment.api_url + 'logout/impersonate', {params: params}).subscribe(
            data => {
                localStorage.removeItem('impersonated_user');
                localStorage.removeItem('impersonated_fullName');
                this.handleLogin();
            });
    }

    setLoggedIn(user: any): void {
        if (user) {
            localStorage.setItem('isLoggedIn', 'true');
        } else {
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('impersonated_user');
            localStorage.removeItem('impersonated_fullName');
        }
    }

    handleUnauthorized(returnUrl?: string) {
        const appComponentSource = this.appComponentSource;
        // open modal
        appComponentSource.next(true);
        this.applicationState.stopRenewSession();
        this.setLoggedIn(null);
        const router = this.router;
        setTimeout(function() {
            // close modal
            appComponentSource.next(false);
            if (!!returnUrl) {
                router.navigate(['auth/login'], {queryParams: {returnUrl: returnUrl}});
            } else {
                router.navigate(['auth/login']);
            }
        }, 2000);
    }

}
