import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {BreadcrumbService} from '@/app/services/breadcrumb.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class LocationService {

    constructor(private location: Location, private breadcrumbService: BreadcrumbService, private router: Router) {
    }

    public replaceState(url: string, nextRouteId?: number): void {
        const currentUrl = this.location.path();
        if (currentUrl.includes('?')) {
            // add client property
            const client = currentUrl.split('?')[1];
            url = url + '?' + client;
        }
        this.location.replaceState(url);
        if (nextRouteId) {
            this.breadcrumbService.setCurrentRouteId(nextRouteId);
        }
    }

    public addState(url: string, nextRouteId?: number): void {
        const currentUrl = this.location.path();
        if (currentUrl.includes('?')) {
            // add client property
            const urlArray = currentUrl.split('?');
            url = urlArray[0] + url + '?' + urlArray[1];
        }
        this.location.replaceState(url);
        if (nextRouteId) {
            this.breadcrumbService.setCurrentRouteId(nextRouteId);
        }
    }

    public getPath(): string {
        return this.location.path();
    }

    public getClient(): string {
        let urlTree = this.router.parseUrl(this.location.path());
        return urlTree.queryParams['client'];
    }
}
