/**
 * Get a deep property from an object
 * @param obj
 * @param fullPath
 * @returns {*}
 */
export function getDeepProperty(obj, fullPath: string) {
    for (let i = 0, path = fullPath.split('.'), len = path.length; i < len; i++) {
        obj = obj && obj[path[i]];
    }
    return obj;
}