<div (mouseenter)="toggle()" (mouseleave)="toggle()" [style.overflow-y]="isHidden ? 'hidden' : 'visible'"
     [style.width.px]="sidebarWidth" class="sidebar">

    <a id="logo_sidebar" routerLink="/">
        <img alt="logo" src="/assets/logo.svg"/>
    </a>

    <ng-container *ngFor="let menuItem of menu">
        <app-menu-item *ngIf="menuItem.allowed" [menuIsHidden]="isHidden" [menuItem]="menuItem"></app-menu-item>
    </ng-container>

    <div class="sidebar__extras">
        <app-sidebar-extras></app-sidebar-extras>
    </div>

</div>