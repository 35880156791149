import {MenuItem} from '../interfaces/IMenuItem';

export function createMenu() {
    return <MenuItem[]>[
        {
            title: 'Model Configuration', url: 'model-configuration', icon: 'build', allowed: false, hasChildren: false, children: [],
        },
        {
            title: 'Insights Update', url: 'insights-update/edit', icon: 'update', allowed: false, hasChildren: false, children: [],
        },
        {
            title: 'Data Overview', url: 'data-overview', icon: 'scatter_plot', allowed: false, hasChildren: false, children: [],
        },
        {
            title: 'Universal Insights', url: 'universal-insights', icon: 'table_view', allowed: false, hasChildren: true, children: [
                {title: 'Media Effectiveness', allowed: false, url: 'universal-insights/media-effectiveness'},
                {title: 'Channel Attribution Insights', allowed: false, url: 'universal-insights/channel-attribution'},
                {title: 'Campaign Attribution Insights', allowed: false, url: 'universal-insights/campaign-attribution'},
                {title: 'Short & Long Term Effects Dashboard', allowed: false, url: 'universal-insights/short-long-term-effects'},
                {title: 'Factors of Influence Insights', allowed: false, url: 'universal-insights/factors-of-influence'},
                {title: 'Model Validation', allowed: false, url: 'universal-insights/model-validation'},
            ],
        },
        {
            title: 'Channel Insights', url: 'channel-insights', icon: 'bar_chart', allowed: false, hasChildren: true, children: [
                {title: 'Media Explorer', allowed: false, url: 'channel-insights/media-explorer'},
                {title: 'Attribution', allowed: false, url: 'channel-insights/modelling-insights'},
            ],
        },
        {
            title: 'Data Quality', url: 'data-quality', icon: 'task', allowed: false, hasChildren: false,
        },
        {
            title: 'Offline Insights', url: 'offline_insights', icon: 'radio', allowed: false, hasChildren: false,
        },
        {
            title: 'Media Budget Optimization', url: 'budget-optimization', icon: 'tune', allowed: false, hasChildren: false,
        },
        {
            title: 'Experiments', url: 'experiments', icon: 'science', allowed: true, hasChildren: false,
        },
        {
            title: 'Media Scenario Planner', url: 'media-scenario-planner', icon: 'directions', allowed: false, hasChildren: false,
        },
        {
            title: 'KPI Segments', url: 'kpi-segments', icon: 'ballot', allowed: false, hasChildren: false,
        },
        {
            title: 'GRP Optimization', url: 'grp_optimization', icon: 'highlight_alt', allowed: false, hasChildren: false,
        },
        {
            title: 'Reach', url: 'reach', icon: 'swap_horizontal_circle', allowed: false, hasChildren: false,
        },
        {
            title: 'Brand Insights', url: 'brand-insights', icon: 'store', allowed: false, hasChildren: true, children: [
                {title: 'Brand Attribution Insights', allowed: false, url: 'brand-insights/brand-attribution'},
            ],
        },
        {
            title: 'Facebook', url: 'facebook', icon: 'facebook', allowed: false, hasChildren: false, children: [],
        },
        {
            title: 'Raw Data', url: 'raw-data', icon: 'folder_open', allowed: false, hasChildren: false, children: [],
        },
        {
            title: 'Campaigns', url: 'campaigns', icon: 'campaign', allowed: false, hasChildren: false,
        },
        {
            title: 'Conversion Segments', url: 'segments', icon: 'scatter_plot', allowed: false, hasChildren: false,
        },
        {
            title: 'Custom Insights', url: 'custom_insights', icon: 'insights', allowed: false, hasChildren: false,
        },
        {
            title: 'Data Input', url: 'data-input', icon: 'note_add', allowed: false, hasChildren: true, children: [
                {title: 'Events', allowed: false, url: 'data-input/events'},
            ],
        },
        {
            title: 'Prototypes', url: 'prototypes', icon: 'view_in_ar', allowed: false, hasChildren: false,
        },
        {
            title: 'Mapping Manager', url: 'mapping_manager', icon: 'rule', allowed: false, hasChildren: false,
        },
        {
            title: 'Admin', url: 'admin', icon: 'settings', allowed: false, hasChildren: true, children: [
                {title: 'Add New Client', allowed: false, url: 'admin/new-client'},
                {title: 'Client Configuration', allowed: false, url: 'admin/client-configuration'},
                {title: 'User Groups', allowed: false, url: 'admin/usergroups'},
                {title: 'Users', allowed: false, url: 'admin/users'},
                {title: 'Translations', allowed: false, url: 'admin/translations'},
                {title: 'Shiny Pages', allowed: false, url: 'admin/external-page/shiny'},
                {title: 'Sisense Pages', allowed: false, url: 'admin/external-page/sisense'},
                {title: 'Prototype Pages', allowed: false, url: 'admin/external-page/prototype'},
                {title: 'Data Upload', allowed: false, url: 'admin/data-upload'},
            ],
        },
    ];
}



