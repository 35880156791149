import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {NotificationService} from '../../services/notification.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../services/authentication.service';
import {ApplicationStateService} from '@/app/services/application-state.service';

@Component({
    selector: 'app-sidebar-extras',
    templateUrl: './sidebar-extras.component.html',
    styleUrls: ['./sidebar-extras.component.scss'],
})
export class SidebarExtrasComponent implements OnInit, OnDestroy {

    options = [
        'I need help getting started',
        'I have an issue with data',
        'I think I found a bug',
        'I would like to suggest a new feature',
        'Other',
    ];
    sendingMail = false;
    supportMailInfo = {
        type: this.options[1],
        subject: '',
        message: '',
    };
    showNotificationAdmin = false;
    subscription: Subscription = new Subscription();

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        private notificationService: NotificationService,
        private modalService: NgbModal,
        private applicationState: ApplicationStateService) {
    }

    ngOnInit(): void {
        this.applicationState.getState('userClientConfig').subscribe((data) => {
            const username = data.user.username;
            this.showNotificationAdmin = username.includes('@objectivepartners.com') || username.includes('@objectiveplatform.com');
        });
    }

    logout() {
        this.authService.logout();
    }

    selectedSupportMailType(option: string) {
        this.supportMailInfo.type = option;
    }

    sendSupportMail() {
        this.sendingMail = true;

        let params = new HttpParams();
        params = params.set('issueType', this.supportMailInfo.type);
        params = params.set('subject', this.supportMailInfo.subject);
        params = params.set('message', this.supportMailInfo.message);

        let headers_object = new HttpHeaders();
        headers_object = headers_object.append('Content-Type', 'application/x-www-form-urlencoded');

        const httpOptions = {
            headers: headers_object,
        };

        this.http.post(environment.api_url + 'basics/supportMail', params, httpOptions).subscribe(
            () => {
                this.notificationService.success(
                    'Thanks for your question, we will keep you posted',
                    'Email successfully sent');
                this.sendingMail = false;
                this.dismiss();
            },
            () => {
                this.sendingMail = false;
            },
        );
    }

    dismiss() {
        this.modalService.dismissAll();
    }

    openSupportModal(content) {
        // reset the screen back to its original in case it has been changed
        this.supportMailInfo = {
            type: this.options[1],
            subject: '',
            message: '',
        };

        this.modalService.open(content);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
