import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ApplicationStateService} from '@/app/services/application-state.service';
import {Location} from '@angular/common';

@Component({
    selector: 'app-dashboard-container',
    templateUrl: './dashboard-container.component.html',
    styleUrls: ['./dashboard-container.component.scss'],
})
export class DashboardContainerComponent implements OnInit, OnDestroy {
    subscription = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private applicationState: ApplicationStateService) {
    }

    ngOnInit(): void {
        this.subscription.add(this.applicationState.getState('clientConfiguration').subscribe((clientConfiguration) => {
            let urlTree = this.router.parseUrl(this.router.url);
            urlTree.queryParams['client'] = clientConfiguration.client;
            this.location.replaceState(urlTree.toString(), undefined, this.location.getState());
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
