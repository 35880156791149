import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {inject} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {Observable} from 'rxjs';

export namespace LoginCheckGuard {

    export const canActivate: CanActivateFn = (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ):
        Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree => {
        function determineRedirect(currentUrl, loggedIn) {
            if (currentUrl !== 'login' && !loggedIn) {
                inject(Router).navigate(['auth/login'], {queryParams: {returnUrl: currentUrl}});
                return false;
            } else if (currentUrl === 'login' && loggedIn) {
                inject(Router).navigateByUrl('/dashboard');
                return false;
            } else {
                return true;
            }
        }

        if (inject(AuthenticationService).loggedIn()) {
            return determineRedirect(route.url[0].path, true);
        } else {
            return determineRedirect(route.url[0].path, false);
        }
    };

    export const canActivateChild: CanActivateChildFn = (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ):
        Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree => {
        return canActivate(route.parent, state);
    };

}
