import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginCheckGuard} from '../services/login-check-guard';
import {DashboardContainerComponent} from '../pages/dashboard-container/dashboard-container.component';
import {ExternalPageComponent} from '../components/external-page/external-page.component';
import {SisenseRedirectComponent} from '../pages/sisense-redirect/sisense-redirect.component';
import {AuthComponent} from '../components/auth/auth.component';
import {AdminGuard} from '../services/admin.guard';
import {UnauthorizedComponent} from '../components/unauthorized/unauthorized.component';

type PathMatch = 'full' | 'prefix' | undefined;

export const routes: Routes = [
    {path: '', redirectTo: '/dashboard', pathMatch: 'full' as PathMatch},
    {
        path: 'dashboard',
        component: DashboardContainerComponent,
        canActivate: [LoginCheckGuard.canActivate],
        canActivateChild: [LoginCheckGuard.canActivateChild],
        children: [
            {
                path: 'channel', redirectTo: 'channel-insights/media-explorer',
                data: {
                    id: 1,
                },
            },
            {
                path: 'channel-insights',
                loadChildren: () => import('../pages/channel-insights/channel-insights.module').then(m => m.ChannelInsightsModule),
            },
            {
                path: 'admin',
                pathMatch: 'prefix' as PathMatch,
                loadChildren: () => import('../pages/admin/admin.module').then(m => m.AdminModule),
                canActivate: [AdminGuard],
            },
            {
                path: 'notifications',
                loadChildren: () => import('../pages/notification/notification.module').then(m => m.NotificationModule),
            },
            {
                path: 'budget-optimization',
                loadChildren: () => import('../pages/budget-optimization/budget-optimization.module').then(m => m.BudgetOptimizationModule),
            },
            {
                path: 'experiments',
                loadChildren: () => import('../pages/experiments/experiments.module').then(m => m.ExperimentsModule),
            },
            {
                path: 'media-scenario-planner',
                loadChildren: () => import('../pages/media-scenario-planner/media-scenario-planner.module').then(m => m.MediaScenarioPlannerModule),
            },
            {
                path: 'kpi-segments',
                loadChildren: () => import('../pages/kpi-segments/kpi-segments.module').then(m => m.KpiSegmentsModule),
            },
            {
                path: 'data-input',
                loadChildren: () => import('../pages/data-input/data-input.module').then(m => m.DataInputModule),
            },
            {
                path: 'data-overview',
                loadChildren: () => import('../pages/data-overview/data-overview.module').then(m => m.DataOverviewModule),
            },
            {
                path: 'model-configuration',
                loadChildren: () => import('../pages/model-configuration/model-configuration.module').then(m => m.ModelConfigurationModule),
            },
            {
                path: 'insights-update',
                loadChildren: () => import('../pages/model-configuration/insights-update.module').then(m => m.InsightsUpdateModule),
            },
            {
                path: 'universal-insights',
                loadChildren: () => import('@/app/pages/fire-dashboards/fire-dashboards.module').then(m => m.FireDashboardsModule),
            },
            {
                path: 'brand-insights',
                loadChildren: () => import('@/app/pages/fire-dashboards/fire-dashboards.module').then(m => m.FireDashboardsModule),
            },
            {
                path: 'data-quality/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 42,
                },
            },
            {
                path: 'campaigns/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 43,
                },
            },
            {
                path: 'grp_optimization/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 44,
                },
            },
            {
                path: 'offline_insights/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 45,
                },
            },
            {
                path: 'homepage/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 46,
                },
            },
            {
                path: 'reach/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 47,
                },
            },
            {
                path: 'custom_insights/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 49,
                },
            },
            {
                path: 'prototypes/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 50,
                },
            },
            {
                path: 'segments/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 51,
                },
            },
            {
                path: 'facebook/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 52,
                },
            },
            {
                path: 'raw-data/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 53,
                },
            },
            {
                path: 'brand-insights/:insightId',
                component: ExternalPageComponent,
                data: {
                    id: 54,
                },
            },
            {
                path: 'universal-insights/:insightId',
                pathMatch: 'full',
                component: ExternalPageComponent,
                data: {
                    id: 55,
                },
            },
            {
                path: 'mapping_manager/:insightId',
                component: ExternalPageComponent,
            },
            {
                path: 'unauthorized',
                component: UnauthorizedComponent,
            },
        ],
    },
    {path: 'sisense-redirect', component: SisenseRedirectComponent},
    {
        path: 'auth',
        component: AuthComponent,
        loadChildren: () => import('../pages/auth/auth.module').then(m => m.AuthModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})

export class AppRoutingModule {
}
