import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit, OnChanges {
    @Input() menuItem;        // the menu item that is displayed
    @Input() menuIsHidden;    // sidebar menu is open or closed
    @Input() submenuIsHidden;  // submenu of menu item is open or closed

    currentUrl;

    constructor(private router: Router) {
        this.currentUrl = this.router.url;
    }

    init() {
        this.submenuIsHidden = !this.menuItem.isActive;
    }

    ngOnInit() {
        this.init();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
                this.calculateActiveUrl();
            }
        });

        this.calculateActiveUrl();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.init();
    }

    calculateActiveUrl() {
        const urlSplit = this.currentUrl.split('/');
        this.menuItem.isActive = urlSplit[2] === this.menuItem.url;
    }

    toggleSubMenu() {
        this.submenuIsHidden = !this.submenuIsHidden;
    }
}
