import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from '@/environments/environment';
import {
    failedUserNotifications,
    loadedUserNotifications,
    readAllDoneUserNotification,
    readDoneUserNotification,
    unreadDoneUserNotification,
    UserNotificationAction,
} from './user-notification.action';
import {UserNotification} from '../notification.interface';
import {SortUtils} from '@/app/utilities/sortUtils';

@Injectable()
export class UserNotificationEffects {

    loadUserNotification$ = createEffect(() => this.actions$.pipe(
        ofType(UserNotificationAction.USER_NOTIFICATION_LOAD),
        mergeMap(() => {
                return this.http.get<UserNotification[]>(environment.api_url + 'notifications/user').pipe(
                    map(result => loadedUserNotifications({data: SortUtils.sortDescByDateProperty(result, 'scheduledDate')})),
                    catchError((error) => of(failedUserNotifications({error}))),
                );
            },
        ),
    ));

    readUserNotification$ = createEffect(() => this.actions$.pipe(
        ofType(UserNotificationAction.USER_NOTIFICATION_READ),
        mergeMap((params: any) => {
                return this.http.patch<UserNotification>(environment.api_url + 'notifications/user/' + params.id + '/read', null).pipe(
                    map(data => readDoneUserNotification({data})),
                    catchError((error) => of(failedUserNotifications({error}))),
                );
            },
        ),
    ));

    unreadUserNotification$ = createEffect(() => this.actions$.pipe(
        ofType(UserNotificationAction.USER_NOTIFICATION_UNREAD),
        mergeMap((params: any) => {
                return this.http.patch<UserNotification>(environment.api_url + 'notifications/user/' + params.id + '/unread', null).pipe(
                    map(data => unreadDoneUserNotification({data})),
                    catchError((error) => of(failedUserNotifications({error}))),
                );
            },
        ),
    ));

    readAllUserNotification$ = createEffect(() => this.actions$.pipe(
        ofType(UserNotificationAction.USER_NOTIFICATION_READ_ALL),
        mergeMap((params: any) => {
                return this.http.patch<UserNotification>(environment.api_url + 'notifications/user/' + params.label + '/read-all', null).pipe(
                    map(data => readAllDoneUserNotification({label: params.label})),
                    catchError((error) => of(failedUserNotifications({error}))),
                );
            },
        ),
    ));

    constructor(private actions$: Actions, private http: HttpClient) {
    }
}
