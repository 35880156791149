import {getDeepProperty} from './objects';
import dayjs from 'dayjs';

export class SortUtils {
    static fieldSorter = (fields) => (a, b) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') {
            dir = -1;
            o = o.substring(1);
        }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0);

    static sortBy(list: any[], property: string) {
        return list.sort((a, b) => this.compareByTypeAndProperty(a, b, property));
    }

    static sortDescBy(list: any[], property: string) {
        return list.sort((a, b) => this.compareByTypeAndProperty(b, a, property));
    }

    static sortByDateProperty(list: any[], property: string) {
        return list.sort((a, b) => this.compareByTypeAndProperty(a, b, property));
    }

    static sortDescByDateProperty(list: any[], property: string) {
        return list.sort((a, b) => new Date(b[property]).getTime() - new Date(a[property]).getTime());
    }

    static sortByTwoProperties(list: any[], property1: string, property2: string) {
        return list.sort(SortUtils.fieldSorter([property1, property2]));
    }

    /**
     Groups array by multiple fields and multiply values
     */
    static groupByAndMultiply(array, groups, valueKey): any[] {
        const
            getKey = o => groups.map(k => o[k]).join('|'),
            getObject = o => Object.fromEntries([...groups.map(k => [k, o[k]]), [valueKey, 1]]);
        groups = [].concat(groups);
        return Object.values(array.reduce((r, o) => {
            (r[getKey(o)] ??= getObject(o))[valueKey] *= o[valueKey];
            return r;
        }, {}));
    }

    private static compareByTypeAndProperty(value1, value2, property) {
        let a;
        let b;
        if (property.includes('.')) {
            a = getDeepProperty(value1, property);
            b = getDeepProperty(value2, property);
        } else {
            a = value1[property];
            b = value2[property];
        }
        if ((a === undefined || a === null) && (b === undefined || b === null)) {
            return 0;
        } else if (a === undefined || a === null) {
            return -1;
        } else if (b === undefined || b === null) {
            return 1;
        }
        if (typeof a === 'string') {
            return a.localeCompare(b);
        }
        if (typeof a === 'number') {
            return a > b ? 1 : a < b ? -1 : 0;
        }
        if (a instanceof Date) {
            return new Date(a).getTime() - new Date(b).getTime();
        }
        if (dayjs.isDayjs(a)) {
            return a.isAfter(b) ? 1 : a.isBefore(b) ? -1 : 0;
        }

    }
}
