import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable()
export class BaseInjector {
    // this injector is used to manually do dependency injection.
    // This is useful in the baseComponent so that child constructors don't need to do the injection for it
    // private static injector: Injector;
    private static injectorSubject = new BehaviorSubject<Injector>(null);

    static setInjector(injector: Injector) {
        BaseInjector.injectorSubject.next(injector);
    }

    static getInjector(): Observable<Injector> {
        return BaseInjector.injectorSubject.pipe(
            filter(result => result !== null));
    }
}
