import {createAction, props} from '@ngrx/store';
import {Notification} from '../notification.interface';

export enum ScheduledNotificationAction {
    SCHEDULED_NOTIFICATION_LOAD = '[SCHEDULED_NOTIFICATION] Load Action',
    SCHEDULED_NOTIFICATION_LOADED = '[SCHEDULED_NOTIFICATION] Loaded Action',
    SCHEDULED_NOTIFICATION_START_EDIT = '[SCHEDULED_NOTIFICATION] Start Edit Action',
    SCHEDULED_NOTIFICATION_SAVE = '[SCHEDULED_NOTIFICATION] Save Action',
    SCHEDULED_NOTIFICATION_SAVED = '[SCHEDULED_NOTIFICATION] Saved Action',
    SCHEDULED_NOTIFICATION_DELETE = '[SCHEDULED_NOTIFICATION] Delete Action',
    SCHEDULED_NOTIFICATION_DELETED = '[SCHEDULED_NOTIFICATION] Deleted Action',
    SCHEDULED_NOTIFICATION_ARCHIVE = '[SCHEDULED_NOTIFICATION] Archive Action',
    SCHEDULED_NOTIFICATION_ARCHIVED = '[SCHEDULED_NOTIFICATION] Archived Action',
    SCHEDULED_NOTIFICATION_RELOAD = '[SCHEDULED_NOTIFICATION] Reload Action',
    SCHEDULED_NOTIFICATION_FAILED = '[SCHEDULED_NOTIFICATION] Failed Action',
}

export const loadScheduledNotifications = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_LOAD,
);

export const loadedScheduledNotifications = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_LOADED,
    props<{ data: Notification[] }>(),
);

export const editScheduledNotification = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_START_EDIT,
    props<{ notification: Notification }>(),
);

export const reloadScheduledNotification = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_RELOAD,
);

export const saveScheduledNotification = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_SAVE,
    props<{ data: Notification }>(),
);

export const savedScheduledNotification = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_SAVED,
    props<{ result: Notification }>(),
);

export const deleteScheduledNotifications = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_DELETE,
    props<{ ids: string[] }>(),
);

export const deletedScheduledNotifications = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_DELETED);

export const archiveScheduledNotification = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_ARCHIVE,
    props<{ id: string }>(),
);

export const archivedScheduledNotification = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_ARCHIVED,
    props<{ data: Notification }>());

export const failedScheduledNotifications = createAction(
    ScheduledNotificationAction.SCHEDULED_NOTIFICATION_FAILED,
    props<{ error: any }>(),
);
