<div class="navbar--top pt-2 pb-2">
    <div *ngIf="impersonatingUsername" class="navbar--top__impersonation">
        <mat-icon>visibility</mat-icon>
        You are impersonating <span class="font--bold">{{ impersonatingUsername }}</span>.
        <a (click)="stopImpersonating()">Stop impersonating</a>
    </div>

    <div class="flex-justify-between">
        <app-breadcrumb></app-breadcrumb>
        <div class="flex-justify-between">
            <app-notification-popup></app-notification-popup>
            <app-client-selection></app-client-selection>
        </div>
    </div>
</div>

