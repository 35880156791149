import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {BreadCrumb, RouteInfo} from '../entities/BreadCrumb';
import {ChildActivationEnd, Router} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {

    routes = [];
    routesSubject = new BehaviorSubject<any>(null);
    currentRouteSubject = new BehaviorSubject<number>(null);
    breadcrumbsSubject = new Subject<BreadCrumb[]>();

    constructor(private router: Router) {
        // Subscribe to router events and filter for relevant ChildActivationEnd events
        this.router.events.subscribe((event) => {
            // Check if the event is a ChildActivationEnd event and if it's relevant
            if (event instanceof ChildActivationEnd && this.isRelevantChildActivationEnd(event)) {
                const { _loadedRoutes, path } = event.snapshot.routeConfig as any;
                this.routes = [];
                // Process each loaded child route, building and pushing new RouteInfo objects
                _loadedRoutes?.forEach((child: any) =>
                    this.buildAndPushRoutes(child, path)
                );

                // Notify subscribers that the routes have been updated
                this.routesSubject.next(this.routes);
            }
        });
    }

    /**
     * Helper method to determine if the event is a relevant ChildActivationEnd.
     * This method checks if the last meaningful segment of the current URL path
     * (i.e., the portion following the configured route path) matches any of
     * the paths in `_loadedRoutes` or directly matches the `routeConfig` path.
     *
     * The method identifies this segment by locating the substring after `routeConfig.path`
     * within the full URL path, removing any query parameters and any leading slashes.
     *
     * If the extracted segment does not match any path in `_loadedRoutes` or the `routeConfig` path,
     * the event is considered irrelevant and will be ignored.
     *
     * Example:
     * - Suppose the URL is '/dashboard/brand-insights/edit/step-two?client=Demo3'.
     * - The last segment, 'edit/step-two', will be extracted after removing the route path.
     * - If 'edit/step-two' is found in `_loadedRoutes` (e.g., as a route path) or matches `routeConfig.path`,
     *   the event will be processed.
     * - Otherwise, the event will be ignored since it doesn't match the expected path for breadcrumb updates.
     *
     * @param event - The router event to check, assumed to be a ChildActivationEnd event.
     * @returns true if the event is relevant (i.e., the last segment matches a route path), false otherwise.
     */
    private isRelevantChildActivationEnd(event: ChildActivationEnd): boolean {
        // Ensure routeConfig exists in the event's snapshot
        if (!event.snapshot.routeConfig) return false;

        // Retrieve the full URL path from the router state, including query parameters
        const fullPath = (event.snapshot as any)._routerState.url;

        // Remove any query parameters from the full path
        const basePath = fullPath.split('?')[0];

        // Find the start index of routePath in basePath
        const routePath = event.snapshot.routeConfig.path;
        const lastSegmentStart = basePath.lastIndexOf(routePath) + routePath.length;

        // Extract the last segment starting right after routePath, and remove any leading slash
        const lastSegment = lastSegmentStart < basePath.length
            ? basePath.substring(lastSegmentStart).replace(/^\/+/, '')
            : '';

        const { _loadedRoutes, path } = event.snapshot.routeConfig as any;

        // Check if the last URL segment matches any path in _loadedRoutes or the routeConfig path itself
        return _loadedRoutes?.some((route: any) => route.path === lastSegment) || path === lastSegment;
    }


    public getRoutesObservable() {
        return this.routesSubject.asObservable();
    }

    public getCurrentRouteObservable() {
        return this.currentRouteSubject.asObservable();
    }

    public getBreadcrumbsObservable() {
        return this.breadcrumbsSubject.asObservable();
    }

    public setBreadcrumbs(breadcrumbs: BreadCrumb[]) {
        this.breadcrumbsSubject.next(breadcrumbs);
    }

    public setCurrentRouteId(routeId: number) {
        this.currentRouteSubject.next(routeId);
    }

    public setBreadcrumbTitle(routeId: number, title: string) {
        const route = this.routes.find(obj => {
            return obj.id === routeId;
        });
        if (route) {
            route.breadcrumb.title = title;
        }
        this.routesSubject.next(this.routes);
    }

    public setBreadcrumbPath(routeId: number, path: string) {
        const route = this.routes.find(obj => {
            return obj.id === routeId;
        });
        if (route) {
            route.path = path;
        }
        this.routesSubject.next(this.routes);
    }

    private buildAndPushRoutes(child, parentPath) {
        this.routes.push(new RouteInfo(child.data?.id, parentPath + '/' + child.path, child.data?.breadcrumb));
    }
}
