import {UrlSerializer} from '@angular/router';
import {Inject, Injectable, Optional} from '@angular/core';
import {APP_BASE_HREF, PathLocationStrategy, PlatformLocation} from '@angular/common';

/**
 * @description
 * we store client name in a local store or in a browser's address bar as parameter
 * when user works with two or more clients, sometimes he opens <a> links in new window using right-click
 * this class adds ?client query param to all links (which has routerLink directive) in the app
 * to prevent getting client name from localstorage
 */
@Injectable()
export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {

    constructor(
        private platformLocation: PlatformLocation,
        private urlSerializer: UrlSerializer,
        @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
    ) {
        super(platformLocation, _baseHref);
    }

    private get search(): string {
        return this.platformLocation?.search ?? '';
    }

    prepareExternalUrl(internal: string): string {
        const path = internal;
        const existingURLSearchParams = new URLSearchParams(this.search);
        let values: Map<string, string> = new Map<string, string>();
        existingURLSearchParams.forEach((value, key) => {
            if (key === 'client') {
                values.set(key, value);
            }
        });
        const existingQueryParams = Object.fromEntries(values);
        const urlTree = this.urlSerializer.parse(path);
        const nextQueryParams = urlTree.queryParams;
        urlTree.queryParams = {...existingQueryParams, ...nextQueryParams};
        return urlTree.toString();
    }

}
